import { PressEvent } from "@react-types/shared";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Image, Button, Card, Spacer, ModalContent, ModalBody, ModalHeader, ModalFooter, Textarea} from '@nextui-org/react';
import ob1 from "./ob1.png";
import ob4 from "./ob4.png";
import ob5 from "./ob5.png";
import { useMemo, useState} from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { InterestSegmentsClient } from "../utils/interest-segments-client";
import { InterestSegment } from "../storyteller-api";
import SubscriptionsManager from "./SubscriptionsManager";


interface OnboardingManagerProps {
    isNewUser: boolean
    topicSetUpNeeded: boolean
    onClose: (e: PressEvent) => void
    setInterestSegments: (interestSegments: InterestSegment[]) => void
    interestSegments: InterestSegment[]
}

function OnboardingManager(props: OnboardingManagerProps) {
    const [modalPage, setModalPage] = useState(props.topicSetUpNeeded ? 0 : 1);

    function renderModalPage(): JSX.Element {
        switch (modalPage) {
            case 0:
                return (
                    <TopicSetupModalContent
                        topicSetUpNeeded={props.topicSetUpNeeded}
                        onCompleteSetup={() => setModalPage(prev => prev + 1)}
                        setInterestSegments={props.setInterestSegments}
                    />
                );
            case 1:
                return (
                    <ConfigurationHelp 
                        isNewUser={props.isNewUser} 
                        onClose={props.onClose} 
                        interestSegments={props.interestSegments}  // Use the local state here
                    />
                );
            default:
                return (<></>);
        }
    }

    return (
        <div className="OnboardingManager">
            {renderModalPage()}
        </div>
    );
}

interface TopicSetupModalContentProps {
    topicSetUpNeeded: boolean
    onCompleteSetup: () => void
    setInterestSegments: (interestSegments: InterestSegment[]) => void
}

export function TopicSetupModalContent(props: TopicSetupModalContentProps) {
    const authenticatedUserContext = useAuthenticatedUser();
    const interestSegmentsClient = useMemo(() => new InterestSegmentsClient(authenticatedUserContext), [authenticatedUserContext]);
    const [initialInterests, setInitialInterests] = useState("");
    const [settingUp, setSettingUp] = useState(false);

    function setupInterestSegments() {
        if (authenticatedUserContext.authenticatedUser) {
            setSettingUp(true);
            interestSegmentsClient.setupInterestSegments(authenticatedUserContext.authenticatedUser.user.id, initialInterests)
                .then(interestSegments => {
                    props.setInterestSegments(interestSegments);
                    props.onCompleteSetup();
                })
                .catch(error => console.error("Failed to set up interest segments!", error))
                .finally(() => setSettingUp(false));
        }
    }

    return (
        <ModalContent>
            <ModalHeader>{props.topicSetUpNeeded ? "Welcome to Edgedive!" : "Need help with your topics?"}</ModalHeader>
            <ModalBody>
                <p>{props.topicSetUpNeeded ? "Let's get you started with some topics." : "We'll set up fresh new ones for you."}</p>
                <Textarea
                    isRequired
                    label="What are you interested in tracking?"
                    placeholder="Ex. giant robots in the news and underground construction technology"
                    value={initialInterests}
                    disabled={settingUp}
                    onChange={(e) => setInitialInterests(e.target.value)}
                >
                </Textarea>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="uppercase border"
                    color="secondary"
                    isDisabled={settingUp || !initialInterests}
                    isLoading={settingUp}
                    onPress={(e) => setupInterestSegments()}
                >
                    {!settingUp ? (<>{props.topicSetUpNeeded ? "Create my topics" : "Refresh my topics"} <FaWandMagicSparkles size={15}/></>) : "Just a second"}
                </Button>
                {props.topicSetUpNeeded }
            </ModalFooter>
        </ModalContent>
    );
}

interface ConfigurationHelpProps {
    isNewUser: boolean
    onClose: (e: PressEvent) => void
    interestSegments: InterestSegment[]
}

function ConfigurationHelp(props: ConfigurationHelpProps) {
    const interestSegmentElements = props.interestSegments.map((segment, index) => (
        <Textarea className="flex flex-col w-full py-2 pointer-events-none"
                        placeholder="Add up to 10 topics..."
                        variant="bordered"
                        minRows={2}
                        value={segment.description}
                        startContent={
                            <Button className='mr-2' isDisabled isIconOnly color="secondary">{index + 1}</Button> 
                        }
                    />
    ));
    return (
        <ModalContent>
            <ModalHeader>{props.isNewUser ? "Welcome to Edgedive!" : "Review your generated topics"}</ModalHeader>
            <ModalBody>
            <div className="OnboardingManager ">
                <p>Feel free to add, edit, or delete below. If everything looks good, start your free daily newsletters tracking these topics! </p>
                {interestSegmentElements}
            </div>
            </ModalBody>
            <ModalFooter>
                <Button className="uppercase" color="primary" onPress={props.onClose}>
                    Edit Topics
                </Button>
                <SubscriptionsManager />
            </ModalFooter>
        </ModalContent>
    );
}

export default OnboardingManager;