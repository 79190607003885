import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authenticateWithLink } from '../../utils/auth-client';
import { AuthenticationLinkForm } from './AuthenticationLinkForm';
import { useAuthenticatedUser } from '../../utils/auth-context';
import { Spinner, Card} from '@nextui-org/react';

function AuthenticationLanding() {
    const authenticatedUserContext = useAuthenticatedUser();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token')
    const email = searchParams.get('email');

    const [invalidLink, setInvalidLink] = useState(false);
    const [authFailed, setAuthFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [resendEmail, setResendEmail] = useState(email || '');

    const navigate = useNavigate();

    useEffect(() => {
        if (!email || !token) {
            setInvalidLink(true);
            setAuthFailed(true);
            setLoading(false);
            return;
        }
        authenticateWithLink(email, token)
            .then(authenticationData => {
                authenticatedUserContext.setAuthenticatedUser(authenticationData);
                setLoading(false);
                navigate(authenticationData.isNewUser ? '/?newUser=true' : '/', { replace: true });
            })
            .catch(error => {
                console.error("Failed to authenticate user.", error);
                setAuthFailed(true);
                setLoading(false);
            });
    }, [email, token, navigate, authenticatedUserContext.setAuthenticatedUser]);

    return (
        <Card className="w-full md:w-1/2 max-w-md p-4 mx-auto" shadow="none">
        <div className='AuthenticationLanding'>
            { loading? (
                    <Spinner label="Authenticating you... Hang tight" color="warning" size="lg"/>
            ) : authFailed ? (
                <div className='borderless-box-container'>
                    <div className='form-heading'>{invalidLink ? "That link doesn't seem right." : "Your link may have expired."}</div>
                    <AuthenticationLinkForm
                        formHeadingText="Would you like us to send you another login link?"
                        submitButtonText="Send me a new link"
                        email={resendEmail}
                        onEmailChange={(e) => setResendEmail(e.target.value)}
                    />
                </div>
            ) : (
                <div className='form-heading'>You've been successfully logged in.<p></p>We are redirecting you to your account.</div>
            )}
        </div>
        </Card>
    );
}

export default AuthenticationLanding;