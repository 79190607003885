import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { NewslettersClient } from "../utils/newsletters-client";
import { AuthenticationLinkForm } from "./authentication/AuthenticationLinkForm";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Card, CardBody, Spacer } from '@nextui-org/react';


function UnsubscribeLanding() {
    const authenticatedUserContext = useAuthenticatedUser();
    const newslettersClient = new NewslettersClient();

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    const navigate = useNavigate();

    const [invalidLink, setInvalidLink] = useState(false);
    const [unsubscribeFailed, setUnsubscribeFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loginEmail, setLoginEmail] = useState(email || '');

    useEffect(() => {
        if (!email || !token) {
            setInvalidLink(true);
            setUnsubscribeFailed(true);
            setLoading(false);
            return;
        }
        newslettersClient.unsubscribeWithLink(email, token)
            .then(() => {
                setLoading(false);
                setUnsubscribeFailed(false);
            })
            .catch(error => {
                console.error("Failed to authenticate user.", error);
                setUnsubscribeFailed(true);
                setLoading(false);
            });
    }, [email, token, navigate, authenticatedUserContext.authenticatedUser])

    return (
        <Card className="w-full md:w-1/2 max-w-md p-4 mx-auto" shadow="none">
        <div className="UnsubscribeLanding">
            { loading? (
                <Card className="w-full" shadow="none">
                    <CardBody className="text-center text-lg">
                        <p>Working on unsubscribing you -- hang tight.</p>
                    </CardBody>
                </Card>
            ) : unsubscribeFailed ? (
                <>
                    <Card className="w-full" shadow="none">
                        <CardBody className="text-center text-lg">
                            <p>{invalidLink ? "That link doesn't seem right." : "That link didn't work."}</p>
                        </CardBody>
                    </Card>
                    <Spacer y={8} /> 
                    <AuthenticationLinkForm
                        formHeadingText="Would you like to log in to update your settings?"
                        submitButtonText="Continue with email"
                        email={loginEmail}
                        onEmailChange={(e) => setLoginEmail(e.target.value)}
                    />
                </>
            ) : (
                <>
                    { authenticatedUserContext.authenticatedUser ? (
                        <Navigate to={"/?unsubscribe=true"}/>
                    ) : (
                        <>
                            <Card className="w-full" shadow="none">
                                <CardBody className="text-center text-lg">
                                    <p>you've been unsubscribed.</p>
                                    <AuthenticationLinkForm
                                        formHeadingText="Dive back in."
                                        submitButtonText="Continue with email"
                                        email={loginEmail}
                                        onEmailChange={(e) => setLoginEmail(e.target.value)}
                                    />
                                </CardBody>
                            </Card>
                            <Spacer y={8} />
                        </>
                    )}
                </>
            )}
        </div>
        </Card>
    )
}

export default UnsubscribeLanding;