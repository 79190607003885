import { Routes, Route, Navigate } from 'react-router-dom';

import AuthenticationLanding from './authentication/AuthenticationLanding'
import Home from './Home';
import { LoginForm } from './authentication/LoginForm';
import UnsubscribeLanding from './UnsubscribeLanding';
import AppNav from './AppNav';
import PrivateRoute, { PublicLanding } from './authentication/PrivateRoute';
import PaymentsLanding from './PaymentsLanding';
import AppFooter from './AppFooter';

function App() {
  return (
    <div className='App flex flex-col min-h-screen'>
      <AppNav />
      <main className='app-content flex-grow'>
        <Routes>
          <Route path='/' element={<PrivateRoute publicLanding={PublicLanding.JOIN}><Home /></PrivateRoute>} />
          <Route path='/join' element={<LoginForm />} />
          <Route path='/login' element={<LoginForm />} />
          <Route path='/authenticate' element={<AuthenticationLanding />} />
          <Route path='/unsubscribe' element={<UnsubscribeLanding />} />
          <Route path='/payments' element={<PrivateRoute publicLanding={PublicLanding.LOGIN}><PaymentsLanding /></PrivateRoute>} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </main>
      <AppFooter />
    </div>
  );
}

export default App;
