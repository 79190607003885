import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {NextUIProvider} from "@nextui-org/react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthenticatedUserProvider } from './utils/auth-context';
import { WebSocketProvider } from './utils/websocket';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <GoogleOAuthProvider clientId='447303427408-6f36rkt68u010qjnqd8q51qqk6jk503h.apps.googleusercontent.com'>
    <React.StrictMode>
      <BrowserRouter>
        <NextUIProvider>
          <AuthenticatedUserProvider>
            <WebSocketProvider>
              <App />
            </WebSocketProvider>
          </AuthenticatedUserProvider>
        </NextUIProvider>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
