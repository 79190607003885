import { useEffect, useMemo, useState } from "react";
import { UsersClient } from "../utils/users-client";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Card, CardBody } from '@nextui-org/react';


function PaymentsLanding() {
    const authenticatedUserContext = useAuthenticatedUser();
    const usersClient = useMemo(() => new UsersClient(authenticatedUserContext), [authenticatedUserContext]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        usersClient.getManageSubscriptionLink(authenticatedUserContext)
            .then(response => {
                window.open(decodeURIComponent(response), '_self');
            })
            .catch(error => {
                console.error("Failed to get customer portal redirect!", error);
            })
            .finally(() => setLoading(false));
    }, [authenticatedUserContext.authenticatedUser])
    return (
        <div className="PaymentsLanding flex justify-center sm:min-h-screen sm:bg-slate-50">
            { <Card className="p-2 w-1/2 h-min mt-20" shadow="none">
                    <CardBody>
                        <div className="w-full text-center">
                            {loading? (
                                <><p className="block font-bold text-center">Authenticating...</p>
                                <p className="text-center">You will be redirected to the payments page in a few moments.</p></>
                            ) : (
                                <p className="block font-bold text-center">Redirecting to the payments page...</p>
                            )}
                        </div>
                    </CardBody>
                </Card>
            }
        </div>
    )
}

export default PaymentsLanding;