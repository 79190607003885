import { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticatedUser } from "./auth-context";
import { AuthenticatedUserResponse } from "../storyteller-api";

export class WebSocketManager {
    private authenticatedUser: AuthenticatedUserResponse;
    private socket: WebSocket;
    private listeners: { [id: string]: (message: string) => void } = {};

    constructor(url: string, authenticatedUser: AuthenticatedUserResponse) {
        console.log('Creating websocket with url:', url, 'and authenticated user:', JSON.stringify(authenticatedUser));
        this.socket = new WebSocket(url);
        this.socket.onopen = this.onOpen;
        this.socket.onmessage = this.onMessage;
        this.socket.onclose = this.onClose;
        this.socket.onerror = this.onError;
        this.authenticatedUser = authenticatedUser;
    }

    public send = (message: string): void => {
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
            console.log('Sent message:', message);
        } else {
            console.warn('WebSocket connection is not open');
        }
    };

    public close = (): void => {
        this.socket.close();
        console.log('WebSocket connection closed');
    };

    public isOpen = (): boolean => {
        return this.socket.readyState === WebSocket.OPEN;
    }

    public getState(): number {
        return this.socket.readyState;
    }

    public getReadableState(): string {
        switch (this.socket.readyState) {
            case WebSocket.CONNECTING:
                return 'CONNECTING';
            case WebSocket.OPEN:
                return 'OPEN';
            case WebSocket.CLOSING:
                return 'CLOSING';
            case WebSocket.CLOSED:
                return 'CLOSED';
            default:
                return 'UNKNOWN';
        }
    }

    public registerListener = (listenerId: string, listener: (message: string) => void): void => {
        this.listeners[listenerId] = listener;
    };

    public unregisterListener = (listenerId: string): void => {
        delete this.listeners[listenerId];
    };


    private onOpen = (): void => {
        console.log('WebSocket connection established');
        this.send(JSON.stringify({
            type: 'authentication',
            message: {
                authenticationToken: this.authenticatedUser.authenticationToken,
                userId: this.authenticatedUser.user.id
            }
        }));
    };

    private onMessage = (event: MessageEvent): void => {
        const message = event.data;
        console.log('Received message:', message);
        console.log('Invoking listeners:', this.listeners);
        this.invokeListeners(message);
    };

    private invokeListeners = (message: string): void => {
        Object.values(this.listeners).forEach((listener) => listener(message));
    };

    private onClose = (): void => {
        console.log('WebSocket connection closed.');
        if (this.authenticatedUser) {
            console.log('Reconnecting.');
            this.socket = new WebSocket(this.socket.url);
            this.socket.onopen = this.onOpen;
            this.socket.onmessage = this.onMessage;
            this.socket.onclose = this.onClose;
            this.socket.onerror = this.onError;
        }
    };

    private onError = (error: Event): void => {
        console.error('WebSocket error:', error);
    };
}

// Create a new context for the WebSocketManager
const WebSocketContext = createContext<WebSocketManager | null>(null);

export const useWebSocket = () => useContext(WebSocketContext);

// Create a provider component that wraps the WebSocketManager instance

export const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
    const authenticatedUserContext = useAuthenticatedUser();
    const [webSocketManager, setWebSocketManager] = useState<WebSocketManager | null>(null);

    useEffect(() => {
        if (authenticatedUserContext.authenticatedUser) {
            // Initialize WebSocket connection
            console.log("websocket manager state:", webSocketManager?.getReadableState());
            if (!webSocketManager) {
                console.log('Setting up new websocket connection.');
                setWebSocketManager(new WebSocketManager(`${process.env.REACT_APP_STORYLINES_BE_WEB_SOCKET_URL}`, authenticatedUserContext.authenticatedUser));
            }

            return () => {
                if (webSocketManager) {
                    webSocketManager.close();
                }
            };
        } else {
            console.debug('No authenticated user. Closing websocket connection.');
            if (webSocketManager) {
                webSocketManager.close();
                setWebSocketManager(null);
            }
        }
    }, [authenticatedUserContext.authenticatedUser, webSocketManager, webSocketManager?.getState()]);

    return (
        <WebSocketContext.Provider value={webSocketManager}>
            {children}
        </WebSocketContext.Provider>
    );
};