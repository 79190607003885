import React, { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticatedUser } from '../../utils/auth-context';
import { Spinner } from '@nextui-org/react';

export enum PublicLanding {
  LOGIN,
  JOIN
}

function getPublicLandingPath(publicLanding: PublicLanding) {
  switch (publicLanding) {
    case PublicLanding.LOGIN:
      return "/login";
    case PublicLanding.JOIN:
      return "/join";
  }
}

interface PrivateRouteProps {
  publicLanding: PublicLanding
  children: ReactNode;
}

export default function PrivateRoute({ publicLanding, children }: PrivateRouteProps): JSX.Element {
  const authenticatedUserContext = useAuthenticatedUser();
  const location = useLocation();

  return authenticatedUserContext.isAuthenticated === null ? (
    <div className='w-fit mx-auto mt-5'><Spinner /></div>
  ) : authenticatedUserContext.authenticatedUser ? (
    <>{children}</>
  ) : (
    <Navigate to={getPublicLandingPath(publicLanding)} state={{ from: location }} replace />
  );
};