/* tslint:disable */
/* eslint-disable */
/**
 * Storylines API
 * DOCFLAG./docs/Index.md
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AppleAuthentication
 */
export interface AppleAuthentication {
    /**
     * The uniquely identifying email of the authenticating entity.
     * @type {string}
     * @memberof AppleAuthentication
     */
    'email': string;
    /**
     * The authentication credential for this authentication method
     * @type {string}
     * @memberof AppleAuthentication
     */
    'credential': string;
}
/**
 * Represents a single article.
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * Unique identifier for the article
     * @type {string}
     * @memberof Article
     */
    'id': string;
    /**
     * The text of the article
     * @type {string}
     * @memberof Article
     */
    'title': string;
    /**
     * The link to the article
     * @type {string}
     * @memberof Article
     */
    'link': string;
    /**
     * Date and time the article was published
     * @type {string}
     * @memberof Article
     */
    'publishedDate'?: string;
    /**
     * A short summary of the contents of the article
     * @type {string}
     * @memberof Article
     */
    'description': string;
    /**
     * The full text of the article
     * @type {string}
     * @memberof Article
     */
    'content': string;
    /**
     * The optional scoring metadata of the Article. This is only provided if the Article is retrieved as part of a relevance search.
     * @type {string}
     * @memberof Article
     */
    'scoringMetadata'?: string;
    /**
     * Date and time the article was published
     * @type {string}
     * @memberof Article
     */
    'createdAt': string;
}
/**
 * A preview of an article. The information in this preview is dependent on the context in which it is shown.
 * @export
 * @interface ArticlePreview
 */
export interface ArticlePreview {
    /**
     * Unique identifier for the article preview
     * @type {string}
     * @memberof ArticlePreview
     */
    'id': string;
    /**
     * Unique identifier for the underlying article
     * @type {string}
     * @memberof ArticlePreview
     */
    'articleId': string;
    /**
     * The title of the article
     * @type {string}
     * @memberof ArticlePreview
     */
    'title': string;
    /**
     * The link to the article
     * @type {string}
     * @memberof ArticlePreview
     */
    'link': string;
    /**
     * Date and time the article was published
     * @type {string}
     * @memberof ArticlePreview
     */
    'publishedDate': string;
    /**
     * Excerpt of the article text to be previewed.
     * @type {string}
     * @memberof ArticlePreview
     */
    'contentExcerpt'?: string;
}
/**
 * Represents a search result for an Article. The search result contains the original article plus the specific ArticleSegments that the Article was matched on, along with relevancy scores.
 * @export
 * @interface ArticleSearchResult
 */
export interface ArticleSearchResult {
    /**
     * Unique identifier for the Article
     * @type {string}
     * @memberof ArticleSearchResult
     */
    'id': string;
    /**
     * 
     * @type {Article}
     * @memberof ArticleSearchResult
     */
    'article': Article;
    /**
     * The segments that were matched, sorted from most relevant to least relevant.
     * @type {Array<ArticleSegment>}
     * @memberof ArticleSearchResult
     */
    'segments': Array<ArticleSegment>;
}
/**
 * Represents a single article segment. An article segment is a section of an Article that is embedded independently.
 * @export
 * @interface ArticleSegment
 */
export interface ArticleSegment {
    /**
     * Unique identifier for the article
     * @type {string}
     * @memberof ArticleSegment
     */
    'id': string;
    /**
     * ID of the segment of the article, if this is an excerpt
     * @type {number}
     * @memberof ArticleSegment
     */
    'segmentId': number;
    /**
     * The text of the article
     * @type {string}
     * @memberof ArticleSegment
     */
    'title': string;
    /**
     * The link to the article
     * @type {string}
     * @memberof ArticleSegment
     */
    'link': string;
    /**
     * Date and time the article was published
     * @type {string}
     * @memberof ArticleSegment
     */
    'publishedDate'?: string;
    /**
     * A short summary of the contents of the Article
     * @type {string}
     * @memberof ArticleSegment
     */
    'description': string;
    /**
     * The text of this segment of the Article
     * @type {string}
     * @memberof ArticleSegment
     */
    'content': string;
    /**
     * The relevancy score of the Segment that was matched. This is only provided if the ArticleSegment is retrieved as part of a relevance search.
     * @type {number}
     * @memberof ArticleSegment
     */
    'score'?: number;
    /**
     * The optional scoring metadata of the ArticleSegment. This is only provided if the ArticleSegment is retrieved as part of a relevance search.
     * @type {string}
     * @memberof ArticleSegment
     */
    'scoringMetadata'?: string;
}
/**
 * 
 * @export
 * @interface AssistantReplyRequest
 */
export interface AssistantReplyRequest {
    /**
     * A JSON string of the MessageDeliveredEvent that this reply is in response to.
     * @type {string}
     * @memberof AssistantReplyRequest
     */
    'messageDeliveredEvent': string;
    /**
     * The text representing the assistant\'s reply.
     * @type {string}
     * @memberof AssistantReplyRequest
     */
    'replyText': string;
}
/**
 * 
 * @export
 * @interface AuthenticatedUserResponse
 */
export interface AuthenticatedUserResponse {
    /**
     * The JWT authentication token to be used for this user
     * @type {string}
     * @memberof AuthenticatedUserResponse
     */
    'authenticationToken': string;
    /**
     * True if the user was just created due to this request, false otherwise.
     * @type {boolean}
     * @memberof AuthenticatedUserResponse
     */
    'isNewUser': boolean;
    /**
     * 
     * @type {User}
     * @memberof AuthenticatedUserResponse
     */
    'user': User;
}
/**
 * Authentication methods supported by the platform
 * @export
 * @interface AuthenticationMethod
 */
export interface AuthenticationMethod {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'type': AuthenticationMethodTypeEnum;
    /**
     * 
     * @type {AuthenticationMethodAuthenticationData}
     * @memberof AuthenticationMethod
     */
    'authenticationData': AuthenticationMethodAuthenticationData;
}

export const AuthenticationMethodTypeEnum = {
    Apple: 'apple',
    Google: 'google',
    Email: 'email',
    Link: 'link'
} as const;

export type AuthenticationMethodTypeEnum = typeof AuthenticationMethodTypeEnum[keyof typeof AuthenticationMethodTypeEnum];

/**
 * @type AuthenticationMethodAuthenticationData
 * @export
 */
export type AuthenticationMethodAuthenticationData = AppleAuthentication | EmailAuthentication | GoogleAuthentication | LinkAuthentication;

/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {User}
     * @memberof CreateUserRequest
     */
    'user': User;
    /**
     * 
     * @type {AuthenticationMethod}
     * @memberof CreateUserRequest
     */
    'authenticationMethod': AuthenticationMethod;
    /**
     * 
     * @type {UpdateInterestsRequest}
     * @memberof CreateUserRequest
     */
    'interests'?: UpdateInterestsRequest;
}
/**
 * 
 * @export
 * @interface EmailAuthentication
 */
export interface EmailAuthentication {
    /**
     * The uniquely identifying email of the authenticating entity. This is a required field for email authentication.
     * @type {string}
     * @memberof EmailAuthentication
     */
    'email': string;
    /**
     * The provided password. This field is required in email authentication, and a null value will result in a failed authentication.
     * @type {string}
     * @memberof EmailAuthentication
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface GoogleAuthentication
 */
export interface GoogleAuthentication {
    /**
     * The authentication credential for this authentication method
     * @type {string}
     * @memberof GoogleAuthentication
     */
    'credential': string;
}
/**
 * Represents an inferred interest from the user\'s input.
 * @export
 * @interface InferredInterest
 */
export interface InferredInterest {
    /**
     * The description of the inferred interest.
     * @type {string}
     * @memberof InferredInterest
     */
    'description': string;
}
/**
 * Represents an interest segment for a user.
 * @export
 * @interface InterestSegment
 */
export interface InterestSegment {
    /**
     * The id of the interest segment.
     * @type {string}
     * @memberof InterestSegment
     */
    'id': string;
    /**
     * The description of the interest segment.
     * @type {string}
     * @memberof InterestSegment
     */
    'description': string;
}
/**
 * Represents a user\'s interests.
 * @export
 * @interface Interests
 */
export interface Interests {
    /**
     * User ID that owns these interests
     * @type {string}
     * @memberof Interests
     */
    'userId': string;
    /**
     * The inputted text of the user\'s interests.
     * @type {string}
     * @memberof Interests
     */
    'inputtedText': string;
    /**
     * List of inferred interests from the user input.
     * @type {Array<InferredInterest>}
     * @memberof Interests
     */
    'inferredInterests'?: Array<InferredInterest>;
}
/**
 * 
 * @export
 * @interface LinkAuthentication
 */
export interface LinkAuthentication {
    /**
     * The uniquely identifying email of the authenticating entity. This is a required field for email authentication.
     * @type {string}
     * @memberof LinkAuthentication
     */
    'email': string;
    /**
     * The temporary, link-based authentication token for the user.
     * @type {string}
     * @memberof LinkAuthentication
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface LinkAuthenticationRequest
 */
export interface LinkAuthenticationRequest {
    /**
     * The uniquely identifying email of the authenticating entity. This is a required field for email authentication.
     * @type {string}
     * @memberof LinkAuthenticationRequest
     */
    'email': string;
    /**
     * The initial preferences of the authenticating user. This is required if this is an initial signup, and ignored if it is an existing user login.
     * @type {string}
     * @memberof LinkAuthenticationRequest
     */
    'initialPreferences'?: string;
    /**
     * Whether this is a request to create a new user or login as an existing user. If empty, a new user will be created by default.
     * @type {boolean}
     * @memberof LinkAuthenticationRequest
     */
    'isNewUser'?: boolean;
}
/**
 * A request to log in a User. Either an existing authentication token may be provided, or a new authentication can be processed via an AuthenticationMethod.
 * @export
 * @interface LoginUserRequest
 */
export interface LoginUserRequest {
    /**
     * The Village-generated authentication token for the user
     * @type {string}
     * @memberof LoginUserRequest
     */
    'token'?: string;
    /**
     * 
     * @type {AuthenticationMethod}
     * @memberof LoginUserRequest
     */
    'authenticationMethod'?: AuthenticationMethod;
}
/**
 * A metadata that captures the article data to generate a newsletter
 * @export
 * @interface NewsletterMetadata
 */
export interface NewsletterMetadata {
    /**
     * Unique identifier of the newsletter.
     * @type {string}
     * @memberof NewsletterMetadata
     */
    'newsletterId': string;
    /**
     * 
     * @type {Array<NewsletterMetadataSegmentsInner>}
     * @memberof NewsletterMetadata
     */
    'segments': Array<NewsletterMetadataSegmentsInner>;
}
/**
 * An instance of an ArticleSegment used in the owning newsletter.
 * @export
 * @interface NewsletterMetadataSegmentsInner
 */
export interface NewsletterMetadataSegmentsInner {
    /**
     * Unique identifier of the article
     * @type {string}
     * @memberof NewsletterMetadataSegmentsInner
     */
    'articleId': string;
    /**
     * ID of the segment within the article, if this is an excerpt.
     * @type {number}
     * @memberof NewsletterMetadataSegmentsInner
     */
    'segmentId': number;
    /**
     * The scoring metadata of the article that was used to determine this segment\'s inclusion in the newsletter.
     * @type {string}
     * @memberof NewsletterMetadataSegmentsInner
     */
    'scoringMetadata': string;
}
/**
 * Represents a user\'s limits on their newsletter sends and replies.
 * @export
 * @interface NewsletterRateLimits
 */
export interface NewsletterRateLimits {
    /**
     * 
     * @type {RateLimit}
     * @memberof NewsletterRateLimits
     */
    'dailySendLimit'?: RateLimit;
    /**
     * 
     * @type {RateLimit}
     * @memberof NewsletterRateLimits
     */
    'monthlySendLimit'?: RateLimit;
    /**
     * 
     * @type {RateLimit}
     * @memberof NewsletterRateLimits
     */
    'dailyReplyLimit'?: RateLimit;
    /**
     * 
     * @type {RateLimit}
     * @memberof NewsletterRateLimits
     */
    'monthlyReplyLimit'?: RateLimit;
}
/**
 * Status of newsletter send
 * @export
 * @enum {string}
 */

export const NewsletterSendStatus = {
    Initiated: 'INITIATED',
    Generated: 'GENERATED',
    Sending: 'SENDING',
    Sent: 'SENT',
    Failed: 'FAILED'
} as const;

export type NewsletterSendStatus = typeof NewsletterSendStatus[keyof typeof NewsletterSendStatus];


/**
 * 
 * @export
 * @interface NewsletterSubscription
 */
export interface NewsletterSubscription {
    /**
     * The ID of the newsletter that the user is subscribed to.
     * @type {string}
     * @memberof NewsletterSubscription
     */
    'newsletterId': string;
    /**
     * Date and time the user subscribed to the specified newsletter
     * @type {string}
     * @memberof NewsletterSubscription
     */
    'subscribedAt': string;
}
/**
 * Type of newsletter update
 * @export
 * @enum {string}
 */

export const NewsletterType = {
    Daily: 'daily',
    Custom: 'custom',
    Initial: 'initial',
    Qa: 'qa'
} as const;

export type NewsletterType = typeof NewsletterType[keyof typeof NewsletterType];


/**
 * Represents a newsletter update that was generated and sent.
 * @export
 * @interface NewsletterUpdate
 */
export interface NewsletterUpdate {
    /**
     * Unique identifier for the newsletter update
     * @type {string}
     * @memberof NewsletterUpdate
     */
    'id': string;
    /**
     * ID of the user who received the newsletter update
     * @type {string}
     * @memberof NewsletterUpdate
     */
    'recipientUserId': string;
    /**
     * Subject of the newsletter update
     * @type {string}
     * @memberof NewsletterUpdate
     */
    'subject': string;
    /**
     * HTML content of the newsletter update
     * @type {string}
     * @memberof NewsletterUpdate
     */
    'htmlContent': string;
    /**
     * 
     * @type {NewsletterSendStatus}
     * @memberof NewsletterUpdate
     */
    'status': NewsletterSendStatus;
    /**
     * Date and time the newsletter update was sent
     * @type {string}
     * @memberof NewsletterUpdate
     */
    'sentAt'?: string;
}


/**
 * Represents a generated podcast with a link to the associated audio file.
 * @export
 * @interface Podcast
 */
export interface Podcast {
    /**
     * Unique identifier for the podcast.
     * @type {string}
     * @memberof Podcast
     */
    'id': string;
    /**
     * Title of the podcast.
     * @type {string}
     * @memberof Podcast
     */
    'title': string;
    /**
     * The link to the audio file.
     * @type {string}
     * @memberof Podcast
     */
    'audioLink': string;
    /**
     * The name of the file blob stored in cloud storage.
     * @type {string}
     * @memberof Podcast
     */
    'blobName': string;
    /**
     * Date and time the podcast was generated.
     * @type {string}
     * @memberof Podcast
     */
    'createdAt': string;
}
/**
 * Podcast generation configuration
 * @export
 * @interface PodcastGenerationStrategy
 */
export interface PodcastGenerationStrategy {
    /**
     * 
     * @type {RelevanceStrategy}
     * @memberof PodcastGenerationStrategy
     */
    'relevanceStrategy'?: RelevanceStrategy;
    /**
     * Strategy to use when synthesizing speech
     * @type {string}
     * @memberof PodcastGenerationStrategy
     */
    'speechSynthesisStrategy'?: PodcastGenerationStrategySpeechSynthesisStrategyEnum;
    /**
     * Custom prompt to use when generating the podcast. If this is provided, the useSsml flag is ignored.
     * @type {string}
     * @memberof PodcastGenerationStrategy
     */
    'generationPrompt'?: string;
}

export const PodcastGenerationStrategySpeechSynthesisStrategyEnum = {
    Google: 'google',
    Azure: 'azure'
} as const;

export type PodcastGenerationStrategySpeechSynthesisStrategyEnum = typeof PodcastGenerationStrategySpeechSynthesisStrategyEnum[keyof typeof PodcastGenerationStrategySpeechSynthesisStrategyEnum];

/**
 * Represents a rate limit and its utilization.
 * @export
 * @interface RateLimit
 */
export interface RateLimit {
    /**
     * Allocated limit for the unit of this resource.
     * @type {number}
     * @memberof RateLimit
     */
    'limit': number;
    /**
     * Number of units of this resource that have been utilized during the current rate limit period.
     * @type {number}
     * @memberof RateLimit
     */
    'utilization': number;
    /**
     * Time at which this rate limit resets.
     * @type {string}
     * @memberof RateLimit
     */
    'resetDate': string;
}
/**
 * Strategy to use when identifying news updates relevant to the user.
 * @export
 * @enum {string}
 */

export const RelevanceStrategy = {
    L2: 'l2',
    Cosine: 'cosine',
    InnerProduct: 'inner_product',
    EmbeddingSimilarityLegacy: 'embedding_similarity_legacy',
    TopicBucketing: 'topic_bucketing'
} as const;

export type RelevanceStrategy = typeof RelevanceStrategy[keyof typeof RelevanceStrategy];


/**
 * Represents a tier of functionality that a user can be subscribed to.
 * @export
 * @enum {string}
 */

export const SubscriptionTier = {
    WaitList: 'WAIT_LIST',
    Beta: 'BETA',
    Free: 'FREE',
    FreeTrial: 'FREE_TRIAL',
    Base: 'BASE',
    PaidTierPersonal: 'PAID_TIER_PERSONAL',
    PaidTierProfessional: 'PAID_TIER_PROFESSIONAL',
    Employee: 'EMPLOYEE',
    Inactive: 'INACTIVE'
} as const;

export type SubscriptionTier = typeof SubscriptionTier[keyof typeof SubscriptionTier];


/**
 * A news topic or genre.
 * @export
 * @enum {string}
 */

export const Topic = {
    Us: 'us',
    Politics: 'politics',
    Business: 'business',
    Economy: 'economy',
    Sports: 'sports',
    Finance: 'finance',
    Tech: 'tech',
    Fitness: 'fitness',
    World: 'world',
    ArtsAndCulture: 'arts_and_culture',
    Lifestyle: 'lifestyle',
    RealEstate: 'real_estate',
    PersonalFinance: 'personal_finance',
    Health: 'health',
    Science: 'science',
    Style: 'style'
} as const;

export type Topic = typeof Topic[keyof typeof Topic];


/**
 * A request to update the user\'s interests.
 * @export
 * @interface UpdateInterestsRequest
 */
export interface UpdateInterestsRequest {
    /**
     * Text submitted by the user representing their interests
     * @type {string}
     * @memberof UpdateInterestsRequest
     */
    'interestsText': string;
}
/**
 * The response from updating the user\'s interests.
 * @export
 * @interface UpdateInterestsResponse
 */
export interface UpdateInterestsResponse {
    /**
     * 
     * @type {Interests}
     * @memberof UpdateInterestsResponse
     */
    'updatedInterests': Interests;
}
/**
 * Represents a user with an id and a name.
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique identifier for the user
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * The first (given) name of the user.
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * The last (sur/family) name of the user.
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * The display name for the user.
     * @type {string}
     * @memberof User
     */
    'displayName': string;
    /**
     * The email address of the user.
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * User\'s preferred locale (e.g. en_US, es_MX)
     * @type {string}
     * @memberof User
     */
    'locale': string;
    /**
     * String representation of the User\'s time zone, or PST if there is none provided.
     * @type {string}
     * @memberof User
     */
    'timeZone'?: string;
    /**
     * Date and time the user was created
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * URL of the image.
     * @type {string}
     * @memberof User
     */
    'profileImageUrl'?: string;
    /**
     * User\'s phone number
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * Session key for the checkout page if an existing customer exists
     * @type {string}
     * @memberof User
     */
    'checkoutSessionKey'?: string;
    /**
     * 
     * @type {SubscriptionTier}
     * @memberof User
     */
    'subscriptionTier': SubscriptionTier;
    /**
     * True if the user has started or previously used their free trial, false otherwise.
     * @type {boolean}
     * @memberof User
     */
    'trialed': boolean;
    /**
     * True if the user\'s email is verified as real, false otherwise.
     * @type {boolean}
     * @memberof User
     */
    'verified': boolean;
}



/**
 * ArticleInfoApi - axios parameter creator
 * @export
 */
export const ArticleInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get total article word counts for the given timeframe
         * @param {string} publishedAfter The date after which to fetch a total article word count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleWordCount: async (publishedAfter: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishedAfter' is not null or undefined
            assertParamExists('getArticleWordCount', 'publishedAfter', publishedAfter)
            const localVarPath = `/articles/info/wordCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (publishedAfter !== undefined) {
                localVarQueryParameter['publishedAfter'] = (publishedAfter as any instanceof Date) ?
                    (publishedAfter as any).toISOString() :
                    publishedAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleInfoApi - functional programming interface
 * @export
 */
export const ArticleInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get total article word counts for the given timeframe
         * @param {string} publishedAfter The date after which to fetch a total article word count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleWordCount(publishedAfter: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleWordCount(publishedAfter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleInfoApi.getArticleWordCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticleInfoApi - factory interface
 * @export
 */
export const ArticleInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get total article word counts for the given timeframe
         * @param {string} publishedAfter The date after which to fetch a total article word count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleWordCount(publishedAfter: string, options?: any): AxiosPromise<number> {
            return localVarFp.getArticleWordCount(publishedAfter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleInfoApi - object-oriented interface
 * @export
 * @class ArticleInfoApi
 * @extends {BaseAPI}
 */
export class ArticleInfoApi extends BaseAPI {
    /**
     * 
     * @summary Get total article word counts for the given timeframe
     * @param {string} publishedAfter The date after which to fetch a total article word count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleInfoApi
     */
    public getArticleWordCount(publishedAfter: string, options?: RawAxiosRequestConfig) {
        return ArticleInfoApiFp(this.configuration).getArticleWordCount(publishedAfter, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticlePreviewsApi - axios parameter creator
 * @export
 */
export const ArticlePreviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find article previews by interest segment
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInterestSegment: async (userId: string, interestSegmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('findByInterestSegment', 'userId', userId)
            // verify required parameter 'interestSegmentId' is not null or undefined
            assertParamExists('findByInterestSegment', 'interestSegmentId', interestSegmentId)
            const localVarPath = `/articlePreviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (interestSegmentId !== undefined) {
                localVarQueryParameter['interestSegmentId'] = interestSegmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticlePreviewsApi - functional programming interface
 * @export
 */
export const ArticlePreviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticlePreviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find article previews by interest segment
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByInterestSegment(userId: string, interestSegmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticlePreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByInterestSegment(userId, interestSegmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlePreviewsApi.findByInterestSegment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticlePreviewsApi - factory interface
 * @export
 */
export const ArticlePreviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticlePreviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find article previews by interest segment
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByInterestSegment(userId: string, interestSegmentId: string, options?: any): AxiosPromise<Array<ArticlePreview>> {
            return localVarFp.findByInterestSegment(userId, interestSegmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticlePreviewsApi - object-oriented interface
 * @export
 * @class ArticlePreviewsApi
 * @extends {BaseAPI}
 */
export class ArticlePreviewsApi extends BaseAPI {
    /**
     * 
     * @summary Find article previews by interest segment
     * @param {string} userId ID of the user
     * @param {string} interestSegmentId The id of the interest segment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlePreviewsApi
     */
    public findByInterestSegment(userId: string, interestSegmentId: string, options?: RawAxiosRequestConfig) {
        return ArticlePreviewsApiFp(this.configuration).findByInterestSegment(userId, interestSegmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssistantsApi - axios parameter creator
 * @export
 */
export const AssistantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Receive a request from a user to an assistant.
         * @param {string} body Request object containing the request to the assistant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantsReceive: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('assistantsReceive', 'body', body)
            const localVarPath = `/assistants/receive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a reply from an assistant to a user for a request.
         * @param {AssistantReplyRequest} assistantReplyRequest Request object containing the reply from the assistant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantsReply: async (assistantReplyRequest: AssistantReplyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assistantReplyRequest' is not null or undefined
            assertParamExists('assistantsReply', 'assistantReplyRequest', assistantReplyRequest)
            const localVarPath = `/assistants/reply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assistantReplyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssistantsApi - functional programming interface
 * @export
 */
export const AssistantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssistantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Receive a request from a user to an assistant.
         * @param {string} body Request object containing the request to the assistant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantsReceive(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantsReceive(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantsApi.assistantsReceive']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post a reply from an assistant to a user for a request.
         * @param {AssistantReplyRequest} assistantReplyRequest Request object containing the reply from the assistant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantsReply(assistantReplyRequest: AssistantReplyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantsReply(assistantReplyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantsApi.assistantsReply']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssistantsApi - factory interface
 * @export
 */
export const AssistantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssistantsApiFp(configuration)
    return {
        /**
         * 
         * @summary Receive a request from a user to an assistant.
         * @param {string} body Request object containing the request to the assistant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantsReceive(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.assistantsReceive(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a reply from an assistant to a user for a request.
         * @param {AssistantReplyRequest} assistantReplyRequest Request object containing the reply from the assistant.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantsReply(assistantReplyRequest: AssistantReplyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.assistantsReply(assistantReplyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssistantsApi - object-oriented interface
 * @export
 * @class AssistantsApi
 * @extends {BaseAPI}
 */
export class AssistantsApi extends BaseAPI {
    /**
     * 
     * @summary Receive a request from a user to an assistant.
     * @param {string} body Request object containing the request to the assistant.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantsApi
     */
    public assistantsReceive(body: string, options?: RawAxiosRequestConfig) {
        return AssistantsApiFp(this.configuration).assistantsReceive(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a reply from an assistant to a user for a request.
     * @param {AssistantReplyRequest} assistantReplyRequest Request object containing the reply from the assistant.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantsApi
     */
    public assistantsReply(assistantReplyRequest: AssistantReplyRequest, options?: RawAxiosRequestConfig) {
        return AssistantsApiFp(this.configuration).assistantsReply(assistantReplyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InterestSegmentsApi - axios parameter creator
 * @export
 */
export const InterestSegmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add an interest segment for the user.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new interest segment
         * @param {string} [userId] ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInterestSegment: async (updateInterestsRequest: UpdateInterestsRequest, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInterestsRequest' is not null or undefined
            assertParamExists('addInterestSegment', 'updateInterestsRequest', updateInterestsRequest)
            const localVarPath = `/interestSegments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInterestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the specified interest segment for the specified user.
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterestSegment: async (userId: string, interestSegmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteInterestSegment', 'userId', userId)
            // verify required parameter 'interestSegmentId' is not null or undefined
            assertParamExists('deleteInterestSegment', 'interestSegmentId', interestSegmentId)
            const localVarPath = `/interestSegments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (interestSegmentId !== undefined) {
                localVarQueryParameter['interestSegmentId'] = interestSegmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all interest segments
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestSegments: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInterestSegments', 'userId', userId)
            const localVarPath = `/interestSegments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set up an initial set of segments for the user.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new user\&#39;s interests to translate into segments
         * @param {string} [userId] ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupInterestSegments: async (updateInterestsRequest: UpdateInterestsRequest, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInterestsRequest' is not null or undefined
            assertParamExists('setupInterestSegments', 'updateInterestsRequest', updateInterestsRequest)
            const localVarPath = `/interestSegments/setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInterestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the specified interest segment for the specified user.
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the updated interest segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterestSegment: async (userId: string, interestSegmentId: string, updateInterestsRequest: UpdateInterestsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateInterestSegment', 'userId', userId)
            // verify required parameter 'interestSegmentId' is not null or undefined
            assertParamExists('updateInterestSegment', 'interestSegmentId', interestSegmentId)
            // verify required parameter 'updateInterestsRequest' is not null or undefined
            assertParamExists('updateInterestSegment', 'updateInterestsRequest', updateInterestsRequest)
            const localVarPath = `/interestSegments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (interestSegmentId !== undefined) {
                localVarQueryParameter['interestSegmentId'] = interestSegmentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInterestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterestSegmentsApi - functional programming interface
 * @export
 */
export const InterestSegmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterestSegmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add an interest segment for the user.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new interest segment
         * @param {string} [userId] ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInterestSegment(updateInterestsRequest: UpdateInterestsRequest, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterestSegment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInterestSegment(updateInterestsRequest, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestSegmentsApi.addInterestSegment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the specified interest segment for the specified user.
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterestSegment(userId: string, interestSegmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterestSegment(userId, interestSegmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestSegmentsApi.deleteInterestSegment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all interest segments
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestSegments(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InterestSegment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterestSegments(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestSegmentsApi.getInterestSegments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set up an initial set of segments for the user.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new user\&#39;s interests to translate into segments
         * @param {string} [userId] ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setupInterestSegments(updateInterestsRequest: UpdateInterestsRequest, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InterestSegment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setupInterestSegments(updateInterestsRequest, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestSegmentsApi.setupInterestSegments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the specified interest segment for the specified user.
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the updated interest segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterestSegment(userId: string, interestSegmentId: string, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterestSegment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterestSegment(userId, interestSegmentId, updateInterestsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestSegmentsApi.updateInterestSegment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InterestSegmentsApi - factory interface
 * @export
 */
export const InterestSegmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterestSegmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add an interest segment for the user.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new interest segment
         * @param {string} [userId] ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInterestSegment(updateInterestsRequest: UpdateInterestsRequest, userId?: string, options?: any): AxiosPromise<InterestSegment> {
            return localVarFp.addInterestSegment(updateInterestsRequest, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the specified interest segment for the specified user.
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterestSegment(userId: string, interestSegmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInterestSegment(userId, interestSegmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all interest segments
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestSegments(userId: string, options?: any): AxiosPromise<Array<InterestSegment>> {
            return localVarFp.getInterestSegments(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set up an initial set of segments for the user.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new user\&#39;s interests to translate into segments
         * @param {string} [userId] ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupInterestSegments(updateInterestsRequest: UpdateInterestsRequest, userId?: string, options?: any): AxiosPromise<Array<InterestSegment>> {
            return localVarFp.setupInterestSegments(updateInterestsRequest, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the specified interest segment for the specified user.
         * @param {string} userId ID of the user
         * @param {string} interestSegmentId The id of the interest segment.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the updated interest segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterestSegment(userId: string, interestSegmentId: string, updateInterestsRequest: UpdateInterestsRequest, options?: any): AxiosPromise<InterestSegment> {
            return localVarFp.updateInterestSegment(userId, interestSegmentId, updateInterestsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterestSegmentsApi - object-oriented interface
 * @export
 * @class InterestSegmentsApi
 * @extends {BaseAPI}
 */
export class InterestSegmentsApi extends BaseAPI {
    /**
     * 
     * @summary Add an interest segment for the user.
     * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new interest segment
     * @param {string} [userId] ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestSegmentsApi
     */
    public addInterestSegment(updateInterestsRequest: UpdateInterestsRequest, userId?: string, options?: RawAxiosRequestConfig) {
        return InterestSegmentsApiFp(this.configuration).addInterestSegment(updateInterestsRequest, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the specified interest segment for the specified user.
     * @param {string} userId ID of the user
     * @param {string} interestSegmentId The id of the interest segment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestSegmentsApi
     */
    public deleteInterestSegment(userId: string, interestSegmentId: string, options?: RawAxiosRequestConfig) {
        return InterestSegmentsApiFp(this.configuration).deleteInterestSegment(userId, interestSegmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all interest segments
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestSegmentsApi
     */
    public getInterestSegments(userId: string, options?: RawAxiosRequestConfig) {
        return InterestSegmentsApiFp(this.configuration).getInterestSegments(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set up an initial set of segments for the user.
     * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new user\&#39;s interests to translate into segments
     * @param {string} [userId] ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestSegmentsApi
     */
    public setupInterestSegments(updateInterestsRequest: UpdateInterestsRequest, userId?: string, options?: RawAxiosRequestConfig) {
        return InterestSegmentsApiFp(this.configuration).setupInterestSegments(updateInterestsRequest, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the specified interest segment for the specified user.
     * @param {string} userId ID of the user
     * @param {string} interestSegmentId The id of the interest segment.
     * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the updated interest segment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestSegmentsApi
     */
    public updateInterestSegment(userId: string, interestSegmentId: string, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig) {
        return InterestSegmentsApiFp(this.configuration).updateInterestSegment(userId, interestSegmentId, updateInterestsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NewslettersApi - axios parameter creator
 * @export
 */
export const NewslettersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check whether all newsletters have been sent succesfully for the given date. Date will be interpreted in server time (GMT).
         * @param {string} [date] The date for which to check daily sends. If not specified, will use today in GMT.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDailySends: async (date?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/newsletters/checkDaily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all newsletter subscriptions for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAll', 'userId', userId)
            const localVarPath = `/newsletters/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific newsletter update
         * @param {string} userId ID of the user
         * @param {string} type newsletter type
         * @param {string} [initiatedAt] time at which the update was initiated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsletterUpdate: async (userId: string, type: string, initiatedAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getNewsletterUpdate', 'userId', userId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getNewsletterUpdate', 'type', type)
            const localVarPath = `/newsletters/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (initiatedAt !== undefined) {
                localVarQueryParameter['initiatedAt'] = (initiatedAt as any instanceof Date) ?
                    (initiatedAt as any).toISOString() :
                    initiatedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user\'s newsletter rate limits.
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRateLimits: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getRateLimits', 'userId', userId)
            const localVarPath = `/newsletters/limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sent newsletter updates.
         * @param {string} userId ID of the user
         * @param {string} [type] newsletter type
         * @param {string} [sentBefore] time before which updates were sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentNewsletterUpdates: async (userId: string, type?: string, sentBefore?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSentNewsletterUpdates', 'userId', userId)
            const localVarPath = `/newsletters/sentUpdates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (sentBefore !== undefined) {
                localVarQueryParameter['sentBefore'] = (sentBefore as any instanceof Date) ?
                    (sentBefore as any).toISOString() :
                    sentBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trigger send of a custom newsletter to a user
         * @param {string} userId ID of the user
         * @param {string} relevanceStrategy Relevance strategy to use. This should be a string value from RelevanceStrategy
         * @param {boolean} useStaticNewsletter If true, generation step is skipped and a generic newsletter is sent instead.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustom: async (userId: string, relevanceStrategy: string, useStaticNewsletter: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendCustom', 'userId', userId)
            // verify required parameter 'relevanceStrategy' is not null or undefined
            assertParamExists('sendCustom', 'relevanceStrategy', relevanceStrategy)
            // verify required parameter 'useStaticNewsletter' is not null or undefined
            assertParamExists('sendCustom', 'useStaticNewsletter', useStaticNewsletter)
            const localVarPath = `/newsletters/sendCustom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (relevanceStrategy !== undefined) {
                localVarQueryParameter['relevanceStrategy'] = relevanceStrategy;
            }

            if (useStaticNewsletter !== undefined) {
                localVarQueryParameter['useStaticNewsletter'] = useStaticNewsletter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trigger send of daily newsletter to all subscribers
         * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDaily: async (relevanceStrategy?: RelevanceStrategy, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/newsletters/sendDaily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (relevanceStrategy !== undefined) {
                localVarQueryParameter['relevanceStrategy'] = relevanceStrategy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trigger send of initial newsletter to a user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInitial: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendInitial', 'userId', userId)
            const localVarPath = `/newsletters/sendInitial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trigger send of newsletter to all subscribers of the given newsletter ID
         * @param {string} newsletterId The ID of the newsletter for which to trigger sends
         * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsletter: async (newsletterId: string, relevanceStrategy?: RelevanceStrategy, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsletterId' is not null or undefined
            assertParamExists('sendNewsletter', 'newsletterId', newsletterId)
            const localVarPath = `/newsletters/{newsletterId}/send`
                .replace(`{${"newsletterId"}}`, encodeURIComponent(String(newsletterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (relevanceStrategy !== undefined) {
                localVarQueryParameter['relevanceStrategy'] = relevanceStrategy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary subscribe the user to a newsletter
         * @param {string} newsletterId ID of the newsletter
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe: async (newsletterId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsletterId' is not null or undefined
            assertParamExists('subscribe', 'newsletterId', newsletterId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('subscribe', 'userId', userId)
            const localVarPath = `/newsletters/{newsletterId}/subscriptions`
                .replace(`{${"newsletterId"}}`, encodeURIComponent(String(newsletterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unsubscribe the user from a newsletter
         * @param {string} newsletterId ID of the newsletter
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribe: async (newsletterId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsletterId' is not null or undefined
            assertParamExists('unsubscribe', 'newsletterId', newsletterId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('unsubscribe', 'userId', userId)
            const localVarPath = `/newsletters/{newsletterId}/subscriptions`
                .replace(`{${"newsletterId"}}`, encodeURIComponent(String(newsletterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unsubscribe the user from the newsletter using a link
         * @param {string} email email of the user
         * @param {string} token token to authorize the unsubscribe operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeWithLink: async (email: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('unsubscribeWithLink', 'email', email)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('unsubscribeWithLink', 'token', token)
            const localVarPath = `/newsletters/unsubscribeWithLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewslettersApi - functional programming interface
 * @export
 */
export const NewslettersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewslettersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check whether all newsletters have been sent succesfully for the given date. Date will be interpreted in server time (GMT).
         * @param {string} [date] The date for which to check daily sends. If not specified, will use today in GMT.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkDailySends(date?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkDailySends(date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.checkDailySends']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get all newsletter subscriptions for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsletterSubscription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.getAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a specific newsletter update
         * @param {string} userId ID of the user
         * @param {string} type newsletter type
         * @param {string} [initiatedAt] time at which the update was initiated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsletterUpdate(userId: string, type: string, initiatedAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsletterUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsletterUpdate(userId, type, initiatedAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.getNewsletterUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a user\'s newsletter rate limits.
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRateLimits(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsletterRateLimits>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRateLimits(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.getRateLimits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sent newsletter updates.
         * @param {string} userId ID of the user
         * @param {string} [type] newsletter type
         * @param {string} [sentBefore] time before which updates were sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSentNewsletterUpdates(userId: string, type?: string, sentBefore?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsletterUpdate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSentNewsletterUpdates(userId, type, sentBefore, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.getSentNewsletterUpdates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary trigger send of a custom newsletter to a user
         * @param {string} userId ID of the user
         * @param {string} relevanceStrategy Relevance strategy to use. This should be a string value from RelevanceStrategy
         * @param {boolean} useStaticNewsletter If true, generation step is skipped and a generic newsletter is sent instead.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCustom(userId: string, relevanceStrategy: string, useStaticNewsletter: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCustom(userId, relevanceStrategy, useStaticNewsletter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.sendCustom']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary trigger send of daily newsletter to all subscribers
         * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDaily(relevanceStrategy?: RelevanceStrategy, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDaily(relevanceStrategy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.sendDaily']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary trigger send of initial newsletter to a user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInitial(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInitial(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.sendInitial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary trigger send of newsletter to all subscribers of the given newsletter ID
         * @param {string} newsletterId The ID of the newsletter for which to trigger sends
         * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNewsletter(newsletterId: string, relevanceStrategy?: RelevanceStrategy, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNewsletter(newsletterId, relevanceStrategy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.sendNewsletter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary subscribe the user to a newsletter
         * @param {string} newsletterId ID of the newsletter
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe(newsletterId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe(newsletterId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.subscribe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary unsubscribe the user from a newsletter
         * @param {string} newsletterId ID of the newsletter
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribe(newsletterId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribe(newsletterId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.unsubscribe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary unsubscribe the user from the newsletter using a link
         * @param {string} email email of the user
         * @param {string} token token to authorize the unsubscribe operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribeWithLink(email: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeWithLink(email, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.unsubscribeWithLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NewslettersApi - factory interface
 * @export
 */
export const NewslettersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewslettersApiFp(configuration)
    return {
        /**
         * 
         * @summary Check whether all newsletters have been sent succesfully for the given date. Date will be interpreted in server time (GMT).
         * @param {string} [date] The date for which to check daily sends. If not specified, will use today in GMT.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDailySends(date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkDailySends(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all newsletter subscriptions for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(userId: string, options?: any): AxiosPromise<Array<NewsletterSubscription>> {
            return localVarFp.getAll(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific newsletter update
         * @param {string} userId ID of the user
         * @param {string} type newsletter type
         * @param {string} [initiatedAt] time at which the update was initiated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsletterUpdate(userId: string, type: string, initiatedAt?: string, options?: any): AxiosPromise<NewsletterUpdate> {
            return localVarFp.getNewsletterUpdate(userId, type, initiatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user\'s newsletter rate limits.
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRateLimits(userId: string, options?: any): AxiosPromise<NewsletterRateLimits> {
            return localVarFp.getRateLimits(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sent newsletter updates.
         * @param {string} userId ID of the user
         * @param {string} [type] newsletter type
         * @param {string} [sentBefore] time before which updates were sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentNewsletterUpdates(userId: string, type?: string, sentBefore?: string, options?: any): AxiosPromise<Array<NewsletterUpdate>> {
            return localVarFp.getSentNewsletterUpdates(userId, type, sentBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trigger send of a custom newsletter to a user
         * @param {string} userId ID of the user
         * @param {string} relevanceStrategy Relevance strategy to use. This should be a string value from RelevanceStrategy
         * @param {boolean} useStaticNewsletter If true, generation step is skipped and a generic newsletter is sent instead.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustom(userId: string, relevanceStrategy: string, useStaticNewsletter: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.sendCustom(userId, relevanceStrategy, useStaticNewsletter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trigger send of daily newsletter to all subscribers
         * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDaily(relevanceStrategy?: RelevanceStrategy, options?: any): AxiosPromise<void> {
            return localVarFp.sendDaily(relevanceStrategy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trigger send of initial newsletter to a user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInitial(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendInitial(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trigger send of newsletter to all subscribers of the given newsletter ID
         * @param {string} newsletterId The ID of the newsletter for which to trigger sends
         * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNewsletter(newsletterId: string, relevanceStrategy?: RelevanceStrategy, options?: any): AxiosPromise<void> {
            return localVarFp.sendNewsletter(newsletterId, relevanceStrategy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary subscribe the user to a newsletter
         * @param {string} newsletterId ID of the newsletter
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe(newsletterId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.subscribe(newsletterId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unsubscribe the user from a newsletter
         * @param {string} newsletterId ID of the newsletter
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribe(newsletterId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unsubscribe(newsletterId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unsubscribe the user from the newsletter using a link
         * @param {string} email email of the user
         * @param {string} token token to authorize the unsubscribe operation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeWithLink(email: string, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.unsubscribeWithLink(email, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewslettersApi - object-oriented interface
 * @export
 * @class NewslettersApi
 * @extends {BaseAPI}
 */
export class NewslettersApi extends BaseAPI {
    /**
     * 
     * @summary Check whether all newsletters have been sent succesfully for the given date. Date will be interpreted in server time (GMT).
     * @param {string} [date] The date for which to check daily sends. If not specified, will use today in GMT.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public checkDailySends(date?: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).checkDailySends(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all newsletter subscriptions for the user
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public getAll(userId: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).getAll(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific newsletter update
     * @param {string} userId ID of the user
     * @param {string} type newsletter type
     * @param {string} [initiatedAt] time at which the update was initiated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public getNewsletterUpdate(userId: string, type: string, initiatedAt?: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).getNewsletterUpdate(userId, type, initiatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user\'s newsletter rate limits.
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public getRateLimits(userId: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).getRateLimits(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sent newsletter updates.
     * @param {string} userId ID of the user
     * @param {string} [type] newsletter type
     * @param {string} [sentBefore] time before which updates were sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public getSentNewsletterUpdates(userId: string, type?: string, sentBefore?: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).getSentNewsletterUpdates(userId, type, sentBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trigger send of a custom newsletter to a user
     * @param {string} userId ID of the user
     * @param {string} relevanceStrategy Relevance strategy to use. This should be a string value from RelevanceStrategy
     * @param {boolean} useStaticNewsletter If true, generation step is skipped and a generic newsletter is sent instead.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public sendCustom(userId: string, relevanceStrategy: string, useStaticNewsletter: boolean, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).sendCustom(userId, relevanceStrategy, useStaticNewsletter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trigger send of daily newsletter to all subscribers
     * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public sendDaily(relevanceStrategy?: RelevanceStrategy, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).sendDaily(relevanceStrategy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trigger send of initial newsletter to a user
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public sendInitial(userId: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).sendInitial(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trigger send of newsletter to all subscribers of the given newsletter ID
     * @param {string} newsletterId The ID of the newsletter for which to trigger sends
     * @param {RelevanceStrategy} [relevanceStrategy] relevance strategy to use
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public sendNewsletter(newsletterId: string, relevanceStrategy?: RelevanceStrategy, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).sendNewsletter(newsletterId, relevanceStrategy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary subscribe the user to a newsletter
     * @param {string} newsletterId ID of the newsletter
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public subscribe(newsletterId: string, userId: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).subscribe(newsletterId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unsubscribe the user from a newsletter
     * @param {string} newsletterId ID of the newsletter
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public unsubscribe(newsletterId: string, userId: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).unsubscribe(newsletterId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unsubscribe the user from the newsletter using a link
     * @param {string} email email of the user
     * @param {string} token token to authorize the unsubscribe operation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public unsubscribeWithLink(email: string, token: string, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).unsubscribeWithLink(email, token, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PodcastsApi - axios parameter creator
 * @export
 */
export const PodcastsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate a Podcast
         * @param {string} userId ID of the user
         * @param {PodcastGenerationStrategy} [podcastGenerationStrategy] Strategy to use when generating the podcast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generate: async (userId: string, podcastGenerationStrategy?: PodcastGenerationStrategy, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('generate', 'userId', userId)
            const localVarPath = `/podcasts/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(podcastGenerationStrategy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all podcasts
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('podcastsGet', 'userId', userId)
            const localVarPath = `/podcasts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a podcast
         * @param {string} podcastId ID of the podcast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsPodcastIdDelete: async (podcastId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'podcastId' is not null or undefined
            assertParamExists('podcastsPodcastIdDelete', 'podcastId', podcastId)
            const localVarPath = `/podcasts/{podcastId}`
                .replace(`{${"podcastId"}}`, encodeURIComponent(String(podcastId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PodcastsApi - functional programming interface
 * @export
 */
export const PodcastsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PodcastsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate a Podcast
         * @param {string} userId ID of the user
         * @param {PodcastGenerationStrategy} [podcastGenerationStrategy] Strategy to use when generating the podcast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generate(userId: string, podcastGenerationStrategy?: PodcastGenerationStrategy, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Podcast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generate(userId, podcastGenerationStrategy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodcastsApi.generate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all podcasts
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podcastsGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Podcast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podcastsGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodcastsApi.podcastsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a podcast
         * @param {string} podcastId ID of the podcast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podcastsPodcastIdDelete(podcastId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podcastsPodcastIdDelete(podcastId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodcastsApi.podcastsPodcastIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PodcastsApi - factory interface
 * @export
 */
export const PodcastsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PodcastsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate a Podcast
         * @param {string} userId ID of the user
         * @param {PodcastGenerationStrategy} [podcastGenerationStrategy] Strategy to use when generating the podcast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generate(userId: string, podcastGenerationStrategy?: PodcastGenerationStrategy, options?: any): AxiosPromise<Podcast> {
            return localVarFp.generate(userId, podcastGenerationStrategy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all podcasts
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsGet(userId: string, options?: any): AxiosPromise<Array<Podcast>> {
            return localVarFp.podcastsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a podcast
         * @param {string} podcastId ID of the podcast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podcastsPodcastIdDelete(podcastId: string, options?: any): AxiosPromise<void> {
            return localVarFp.podcastsPodcastIdDelete(podcastId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PodcastsApi - object-oriented interface
 * @export
 * @class PodcastsApi
 * @extends {BaseAPI}
 */
export class PodcastsApi extends BaseAPI {
    /**
     * 
     * @summary Generate a Podcast
     * @param {string} userId ID of the user
     * @param {PodcastGenerationStrategy} [podcastGenerationStrategy] Strategy to use when generating the podcast
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastsApi
     */
    public generate(userId: string, podcastGenerationStrategy?: PodcastGenerationStrategy, options?: RawAxiosRequestConfig) {
        return PodcastsApiFp(this.configuration).generate(userId, podcastGenerationStrategy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all podcasts
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastsApi
     */
    public podcastsGet(userId: string, options?: RawAxiosRequestConfig) {
        return PodcastsApiFp(this.configuration).podcastsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a podcast
     * @param {string} podcastId ID of the podcast
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastsApi
     */
    public podcastsPodcastIdDelete(podcastId: string, options?: RawAxiosRequestConfig) {
        return PodcastsApiFp(this.configuration).podcastsPodcastIdDelete(podcastId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add an inferred interest for the user.
         * @param {string} userId ID of the user
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInferredInterest: async (userId: string, updateInterestsRequest: UpdateInterestsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addInferredInterest', 'userId', userId)
            // verify required parameter 'updateInterestsRequest' is not null or undefined
            assertParamExists('addInferredInterest', 'updateInterestsRequest', updateInterestsRequest)
            const localVarPath = `/users/{userId}/interests/inferred`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInterestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a subscription for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelFreeSubscription: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelFreeSubscription', 'userId', userId)
            const localVarPath = `/users/{userId}/freeSubscription`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a User
         * @param {CreateUserRequest} createUserRequest The User object to be created and authentication information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the inferred interest for the user at the specified index.
         * @param {string} userId ID of the user
         * @param {number} index The index of the inferred interest in the array that should be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInferredInterest: async (userId: string, index: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteInferredInterest', 'userId', userId)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('deleteInferredInterest', 'index', index)
            const localVarPath = `/users/{userId}/interests/{index}/inferred`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a User
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the latest interests for the user.
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestInterests: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getLatestInterests', 'userId', userId)
            const localVarPath = `/users/{userId}/interests`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a User
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log in to an existing User
         * @param {LoginUserRequest} loginUserRequest The authentication information for the User. This endpoint will expect either a valid authentication token OR an email, authentication method, and token which can be validated according to the given auth method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginUserRequest: LoginUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginUserRequest' is not null or undefined
            assertParamExists('login', 'loginUserRequest', loginUserRequest)
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log out an existing User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/refreshAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request authentication via an email link
         * @param {LinkAuthenticationRequest} linkAuthenticationRequest The authentication information for the user, containing the email and, optionally, any additional data associated with login or signup.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestLinkAuthentication: async (linkAuthenticationRequest: LinkAuthenticationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkAuthenticationRequest' is not null or undefined
            assertParamExists('requestLinkAuthentication', 'linkAuthenticationRequest', linkAuthenticationRequest)
            const localVarPath = `/users/linkAuthentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkAuthenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a free subscription for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFreeSubscription: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('startFreeSubscription', 'userId', userId)
            const localVarPath = `/users/{userId}/freeSubscription`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a trial for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFreeTrial: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('startFreeTrial', 'userId', userId)
            const localVarPath = `/users/{userId}/startFreeTrial`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the inferred interest for the user at the specified index.
         * @param {string} userId ID of the user
         * @param {number} index The index of the inferred interest in the array that should be updated.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInferredInterest: async (userId: string, index: number, updateInterestsRequest: UpdateInterestsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateInferredInterest', 'userId', userId)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('updateInferredInterest', 'index', index)
            // verify required parameter 'updateInterestsRequest' is not null or undefined
            assertParamExists('updateInferredInterest', 'updateInterestsRequest', updateInterestsRequest)
            const localVarPath = `/users/{userId}/interests/{index}/inferred`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInterestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the interests for the user.
         * @param {string} userId ID of the user
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the user\&#39;s interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterests: async (userId: string, updateInterestsRequest: UpdateInterestsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateInterests', 'userId', userId)
            // verify required parameter 'updateInterestsRequest' is not null or undefined
            assertParamExists('updateInterests', 'updateInterestsRequest', updateInterestsRequest)
            const localVarPath = `/users/{userId}/interests`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInterestsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a User
         * @param {string} userId ID of the user
         * @param {User} user The User object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add an inferred interest for the user.
         * @param {string} userId ID of the user
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInferredInterest(userId: string, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInterestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInferredInterest(userId, updateInterestsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.addInferredInterest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Cancel a subscription for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelFreeSubscription(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelFreeSubscription(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.cancelFreeSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a User
         * @param {CreateUserRequest} createUserRequest The User object to be created and authentication information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete the inferred interest for the user at the specified index.
         * @param {string} userId ID of the user
         * @param {number} index The index of the inferred interest in the array that should be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInferredInterest(userId: string, index: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInterestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInferredInterest(userId, index, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteInferredInterest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a User
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the latest interests for the user.
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestInterests(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Interests>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestInterests(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getLatestInterests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a User
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Log in to an existing User
         * @param {LoginUserRequest} loginUserRequest The authentication information for the User. This endpoint will expect either a valid authentication token OR an email, authentication method, and token which can be validated according to the given auth method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginUserRequest: LoginUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Log out an existing User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh the access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAccessToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAccessToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.refreshAccessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Request authentication via an email link
         * @param {LinkAuthenticationRequest} linkAuthenticationRequest The authentication information for the user, containing the email and, optionally, any additional data associated with login or signup.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestLinkAuthentication(linkAuthenticationRequest: LinkAuthenticationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestLinkAuthentication(linkAuthenticationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.requestLinkAuthentication']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Start a free subscription for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startFreeSubscription(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startFreeSubscription(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.startFreeSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Start a trial for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startFreeTrial(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startFreeTrial(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.startFreeTrial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the inferred interest for the user at the specified index.
         * @param {string} userId ID of the user
         * @param {number} index The index of the inferred interest in the array that should be updated.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInferredInterest(userId: string, index: number, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInterestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInferredInterest(userId, index, updateInterestsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateInferredInterest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the interests for the user.
         * @param {string} userId ID of the user
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the user\&#39;s interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterests(userId: string, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateInterestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterests(userId, updateInterestsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateInterests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a User
         * @param {string} userId ID of the user
         * @param {User} user The User object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Add an inferred interest for the user.
         * @param {string} userId ID of the user
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInferredInterest(userId: string, updateInterestsRequest: UpdateInterestsRequest, options?: any): AxiosPromise<UpdateInterestsResponse> {
            return localVarFp.addInferredInterest(userId, updateInterestsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a subscription for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelFreeSubscription(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.cancelFreeSubscription(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a User
         * @param {CreateUserRequest} createUserRequest The User object to be created and authentication information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<AuthenticatedUserResponse> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the inferred interest for the user at the specified index.
         * @param {string} userId ID of the user
         * @param {number} index The index of the inferred interest in the array that should be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInferredInterest(userId: string, index: number, options?: any): AxiosPromise<UpdateInterestsResponse> {
            return localVarFp.deleteInferredInterest(userId, index, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a User
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the latest interests for the user.
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestInterests(userId: string, options?: any): AxiosPromise<Interests> {
            return localVarFp.getLatestInterests(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a User
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log in to an existing User
         * @param {LoginUserRequest} loginUserRequest The authentication information for the User. This endpoint will expect either a valid authentication token OR an email, authentication method, and token which can be validated according to the given auth method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginUserRequest: LoginUserRequest, options?: any): AxiosPromise<AuthenticatedUserResponse> {
            return localVarFp.login(loginUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log out an existing User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken(options?: any): AxiosPromise<AuthenticatedUserResponse> {
            return localVarFp.refreshAccessToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request authentication via an email link
         * @param {LinkAuthenticationRequest} linkAuthenticationRequest The authentication information for the user, containing the email and, optionally, any additional data associated with login or signup.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestLinkAuthentication(linkAuthenticationRequest: LinkAuthenticationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.requestLinkAuthentication(linkAuthenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a free subscription for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFreeSubscription(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.startFreeSubscription(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a trial for the user
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startFreeTrial(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.startFreeTrial(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the inferred interest for the user at the specified index.
         * @param {string} userId ID of the user
         * @param {number} index The index of the inferred interest in the array that should be updated.
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInferredInterest(userId: string, index: number, updateInterestsRequest: UpdateInterestsRequest, options?: any): AxiosPromise<UpdateInterestsResponse> {
            return localVarFp.updateInferredInterest(userId, index, updateInterestsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the interests for the user.
         * @param {string} userId ID of the user
         * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the user\&#39;s interests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterests(userId: string, updateInterestsRequest: UpdateInterestsRequest, options?: any): AxiosPromise<UpdateInterestsResponse> {
            return localVarFp.updateInterests(userId, updateInterestsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a User
         * @param {string} userId ID of the user
         * @param {User} user The User object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, user: User, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Add an inferred interest for the user.
     * @param {string} userId ID of the user
     * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addInferredInterest(userId: string, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).addInferredInterest(userId, updateInterestsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a subscription for the user
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public cancelFreeSubscription(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).cancelFreeSubscription(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a User
     * @param {CreateUserRequest} createUserRequest The User object to be created and authentication information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the inferred interest for the user at the specified index.
     * @param {string} userId ID of the user
     * @param {number} index The index of the inferred interest in the array that should be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteInferredInterest(userId: string, index: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteInferredInterest(userId, index, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a User
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the latest interests for the user.
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getLatestInterests(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getLatestInterests(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a User
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log in to an existing User
     * @param {LoginUserRequest} loginUserRequest The authentication information for the User. This endpoint will expect either a valid authentication token OR an email, authentication method, and token which can be validated according to the given auth method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public login(loginUserRequest: LoginUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).login(loginUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log out an existing User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh the access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public refreshAccessToken(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).refreshAccessToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request authentication via an email link
     * @param {LinkAuthenticationRequest} linkAuthenticationRequest The authentication information for the user, containing the email and, optionally, any additional data associated with login or signup.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public requestLinkAuthentication(linkAuthenticationRequest: LinkAuthenticationRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).requestLinkAuthentication(linkAuthenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a free subscription for the user
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public startFreeSubscription(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).startFreeSubscription(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a trial for the user
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public startFreeTrial(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).startFreeTrial(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the inferred interest for the user at the specified index.
     * @param {string} userId ID of the user
     * @param {number} index The index of the inferred interest in the array that should be updated.
     * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the new inferred interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateInferredInterest(userId: string, index: number, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateInferredInterest(userId, index, updateInterestsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the interests for the user.
     * @param {string} userId ID of the user
     * @param {UpdateInterestsRequest} updateInterestsRequest Request object with data about the user\&#39;s interests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateInterests(userId: string, updateInterestsRequest: UpdateInterestsRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateInterests(userId, updateInterestsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a User
     * @param {string} userId ID of the user
     * @param {User} user The User object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, user: User, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet(options).then((request) => request(this.axios, this.basePath));
    }
}



