import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { AuthenticatedUserResponse } from "../storyteller-api";
import { refreshAccessToken } from "./auth-client";

const AuthenticatedUser = createContext<AuthenticatedUserContext>({authenticatedUser: null, setAuthenticatedUser: (() => {}), isAuthenticated: null});

const useAuthenticatedUser = () => useContext(AuthenticatedUser);

const AuthenticatedUserProvider = ({ children }: { children: ReactNode}) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUserResponse | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    refreshAccessToken()
      .then(response => {
        setAuthenticatedUser(response);
        setIsAuthenticated(true);
      })
      .catch(error => {
        console.debug("Couldn't refresh access token: ", error);
        setIsAuthenticated(false);
      });
  }, []);

  return (
    <AuthenticatedUser.Provider value={ { authenticatedUser, setAuthenticatedUser, isAuthenticated} }>
      {children}
    </AuthenticatedUser.Provider>
  );
};

export interface AuthenticatedUserContext {
  authenticatedUser: AuthenticatedUserResponse | null;
  setAuthenticatedUser: React.Dispatch<React.SetStateAction<AuthenticatedUserResponse | null>>;
  isAuthenticated: boolean | null;
}

export { AuthenticatedUserProvider, useAuthenticatedUser }