import { FormEventHandler, useState } from "react";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Button, Card, Spacer, Textarea} from '@nextui-org/react';
import { Navigate } from "react-router-dom";

function SourceRequestManager() {
    const authenticatedUserContext = useAuthenticatedUser();
    const [isSourceRequestInProgress, setIsSourceRequestInProgress] = useState<boolean>(false);
    const [isJustUpdated, setIsJustUpdated] = useState<boolean>(false);
    const [SourceRequestValue, setSourceRequestValue] = useState(''); // State to manage SourceRequest value

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault(); // Prevents default form submission (and redirection)
        const form = e.currentTarget; // Use currentTarget instead of target
        const formData = new FormData(form);
        setIsSourceRequestInProgress(true);
        await fetch('https://script.google.com/macros/s/AKfycbxWhUdrmyl42tg9lak3HOz9lME-6RXdHzz_v23KtyNkxhsncms7VLYFJ7NfuocaIZyp/exec', {
            method: 'POST',
            body: formData,
        })
        setIsSourceRequestInProgress(false);
        setIsJustUpdated(true);
        setSourceRequestValue('');
    };

    return (
        <div className="SourceRequestManager">
            <Card shadow="none">
                { authenticatedUserContext.authenticatedUser ? (
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                    }}>
                        <input 
                            type="hidden" 
                            name="date" 
                            value={new Date(Date.now()).toLocaleString()}>
                        </input>
                        <input 
                            type="hidden" 
                            name="email" 
                            value={authenticatedUserContext.authenticatedUser.user.email}>
                        </input>
                        <Textarea
                            name="feedback"
                            value={SourceRequestValue}
                            onChange={(e) => setSourceRequestValue(e.target.value)}
                            minRows={2}
                            label="Paste source URLs here..."
                            fullWidth
                        >
                        </Textarea>
                        <Spacer y={4} /> 
                        <Button className="uppercase font-sans" color="primary" type="submit" fullWidth
                            isLoading={isSourceRequestInProgress}>
                            Submit Request
                        </Button>
                    </form>
                ) : (
                    <Navigate to='/login' />
                )}
                {isJustUpdated &&
                    <>
                        <Spacer y={4} />
                        <p>Thanks for the request! We'll let you know once your sources have been added.</p>
                    </>
                }
            </Card>
        </div>

    );
}

export default SourceRequestManager;