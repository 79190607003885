import { AxiosRequestConfig } from "axios";
import { AuthenticatedUserContext } from "./auth-context";
import axiosPrivate from "./axios-private";
import { InterestSegment, InterestSegmentsApi, UpdateInterestsRequest } from "../storyteller-api";

export class InterestSegmentsClient {
    protected api: InterestSegmentsApi;

    constructor(authenticatedUserContext: AuthenticatedUserContext) {
        this.api = new InterestSegmentsApi(undefined, process.env.REACT_APP_STORYLINES_BE_BASE_URL, axiosPrivate(authenticatedUserContext));
    }

    public async updateInterestSegment(userId: string, interestSegmentId: string, interestsText: string): Promise<InterestSegment> {
        let updateInterestsRequest: UpdateInterestsRequest = {
            interestsText: interestsText
        };

        try {
            return (await this.api.updateInterestSegment(userId, interestSegmentId, updateInterestsRequest, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to update interest segment", error);
            throw error;
        }
    }

    public async deleteInterestSegment(userId: string, interestSegmentId: string): Promise<void> {
        try {
            await this.api.deleteInterestSegment(userId, interestSegmentId, this.getBasicRequestConfig());
        } catch (error) {
            console.error("Failed to delete interest segment", error);
            throw error;
        }
    }

    public async addInterestSegment(userId: string, interestsText: string): Promise<InterestSegment> {
        let updateInterestsRequest: UpdateInterestsRequest = {
            interestsText: interestsText
        };

        try {
            return (await this.api.addInterestSegment(updateInterestsRequest, userId, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to add interest segment", error);
            throw error;
        }
    }

    public async getInterestSegments(userId: string): Promise<InterestSegment[]> {
        try {
            let response = await this.api.getInterestSegments(userId, this.getBasicRequestConfig());
            return response.data;
        } catch (error) {
            console.error("Failed to get interest segments", error);
            throw error;
        }
    }

    public async setupInterestSegments(userId: string, interestsText: string): Promise<InterestSegment[]> {
        let updateInterestsRequest: UpdateInterestsRequest = {
            interestsText: interestsText
        };

        try {
            return (await this.api.setupInterestSegments(updateInterestsRequest, userId, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to setup interest segments", error);
            throw error;
        }
    }

    private getBasicRequestConfig(): AxiosRequestConfig {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}