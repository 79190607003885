import { FormEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { NewsletterRateLimits, NewsletterSubscription, RateLimit } from "../storyteller-api";
import { NewslettersClient } from "../utils/newsletters-client";
import { useNavigate } from "react-router";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Button, Card, CardBody, Spacer, Badge } from '@nextui-org/react';

function NewsletterSendManager() {
    const authenticatedUserContext = useAuthenticatedUser();
    const newslettersClient = new NewslettersClient(authenticatedUserContext);

    const navigate = useNavigate();

    const [rateLimits, setRateLimits] = useState<NewsletterRateLimits>();
    const [resyncRateLimits, setResyncRateLimits] = useState<boolean>(true);
    const [sendInProgress, setSendInProgress] = useState<boolean>(false);
    const [sendTriggered, setSendTriggered] = useState<boolean>(false);

    const syncRateLimits = useCallback(async () => {
        if (authenticatedUserContext.authenticatedUser) {
            newslettersClient.getRateLimits(authenticatedUserContext.authenticatedUser.user.id).then((newsletterRateLimits) => {
                setRateLimits(newsletterRateLimits);
            }).catch((error) => {
                console.debug('Failed to retrieve newsletter rate limits', error);
            });
        }
    }, [authenticatedUserContext.authenticatedUser]);

    async function onSendNewsletter(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (authenticatedUserContext.authenticatedUser) {
            setSendInProgress(true);
            setSendTriggered(false);
            await newslettersClient.sendCustomNewsletter(authenticatedUserContext.authenticatedUser.user.id);
            setSendInProgress(false);
            setSendTriggered(true);
            setResyncRateLimits(true);
        }
    }

    useEffect(() => {
        if (resyncRateLimits) {
            syncRateLimits();
            setResyncRateLimits(false);
        }
    }, [syncRateLimits, resyncRateLimits]);

    return (
        <div className="NewsletterSendManager flex justify-center">
            <Card shadow="none">
                { rateLimits && (
                    <>
                        <CardBody>
                            <p className="text-md text-center text-gray-800">{getSendHeaderText(rateLimits)}</p>
                        </CardBody>
                        <Button
                            className="uppercase font-sans"
                            color="primary"
                            type="submit"
                            fullWidth
                            isDisabled={isFullyUtilized(rateLimits)}
                            isLoading={sendInProgress}
                            onClick={onSendNewsletter}
                        >
                            {getSendButtonText(rateLimits)}
                        </Button>
                        <CardBody>
                            {!rateLimits.dailySendLimit && !rateLimits.monthlySendLimit ? (
                                <p className="text-xs text-gray-600">Unlimited dives</p>
                            ) : (
                                <>
                                    {rateLimits.dailySendLimit && 
                                        <p className="text-xs text-gray-600">
                                            Remaining dives for today: {Math.max(0, rateLimits.dailySendLimit.limit - rateLimits.dailySendLimit.utilization)}
                                        </p>
                                    }
                                    {rateLimits.monthlySendLimit && (
                                        <p className="text-xs text-gray-600">
                                            Remaining dives for this month: {Math.max(0, rateLimits.monthlySendLimit.limit - rateLimits.monthlySendLimit.utilization)}
                                        </p>
                                    )}
                                </>
                            )}
                            { sendTriggered &&
                                <>
                                <Spacer y={2}/>
                                <p className='text-center text-sm'>watch your inbox - a new dive is on the way!</p>
                                </>
                            }
                        </CardBody>
                    </>
                )}
            </Card>
        </div>
    );
}

function getSendHeaderText(rateLimits: NewsletterRateLimits): string {
    if (isRateLimitFullyUtilized(rateLimits.dailySendLimit)) {
        return "Check in tomorrow if you want another dive!";
    } else if (isRateLimitFullyUtilized(rateLimits.monthlySendLimit)) {
        return "Check in next month if you want another dive!";
    } else {
        return "Updated your interests? Want another dive now?";
    }
}

function getSendButtonText(rateLimits: NewsletterRateLimits): string {
    if (isRateLimitFullyUtilized(rateLimits.dailySendLimit)) {
        return "Out of dives for today";
    } else if (isRateLimitFullyUtilized(rateLimits.monthlySendLimit)) {
        return "Out of dives for this month";
    } else {
        return "Send new dive now";
    }
}

function isFullyUtilized(rateLimits: NewsletterRateLimits): boolean {
    return isRateLimitFullyUtilized(rateLimits.dailySendLimit) || isRateLimitFullyUtilized(rateLimits.monthlySendLimit);
}

function isRateLimitFullyUtilized(rateLimit: RateLimit | undefined): boolean {
    return !!rateLimit && rateLimit.limit <= rateLimit.utilization;
}

export default NewsletterSendManager;