import { useSearchParams } from "react-router-dom";
import InterestsManager, { InterestSegmentMap } from "./InterestsManager";
import SubscriptionsManager from "./SubscriptionsManager";
import SourceRequestManager from "./SourceRequestManager";
import FeedbackManager from "./FeedbackManager";
import { Card, Button, CardBody, Spacer, useDisclosure, Modal, ModalBody, ModalContent, ModalHeader, ModalFooter, Popover, PopoverTrigger, PopoverContent, CardHeader } from '@nextui-org/react';
import NewsletterSendManager from "./NewsletterSendManager";
import { useAuthenticatedUser } from "../utils/auth-context";
import { InterestSegment, SubscriptionTier } from "../storyteller-api";
import OnboardingManager, { TopicSetupModalContent } from "./OnboardingManager";
import InitialNewsletterGenerationManager from "./InitialNewsletterGenerationManager";
import { useCallback, useEffect, useMemo, useState } from 'react';
import {FaCircleInfo, FaWandMagicSparkles } from "react-icons/fa6";
import { InterestSegmentsClient } from "../utils/interest-segments-client";

function Home() {
    const feedbackDisclosure = useDisclosure();
    const sourceRequestDisclosure = useDisclosure();
    const sendManagerDisclosure = useDisclosure();
    const onboardingManagerDisclosure = useDisclosure();
    const authenticatedUserContext = useAuthenticatedUser();
    const interestSegmentsClient = useMemo(() => new InterestSegmentsClient(authenticatedUserContext), [authenticatedUserContext]);
    const { isOpen: isOnboardingOpen, onOpen: onOpenOnboarding, onClose: onCloseOnboarding } = useDisclosure();
    const topicSetupDisclosure = useDisclosure();

    const [searchParams] = useSearchParams();
    const isNewUser = searchParams.get('newUser') === "true";
    const justSubscribed = searchParams.get('subscribed') === "true";
    const justUnsubscribed = searchParams.get('unsubscribe') === "true";

    const [interestSegmentIds, setInterestSegmentIds] = useState<Array<string>>();
    const [interestSegmentMap, setInterestSegmentMap] = useState<InterestSegmentMap>();

    function handleUpdateInterestSegment(interestSegmentId: string, interestSegment: InterestSegment) {
        if (authenticatedUserContext.authenticatedUser) {
            return interestSegmentsClient.updateInterestSegment(authenticatedUserContext.authenticatedUser.user.id, interestSegmentId, interestSegment.description)
                .then(savedInterestSegment => {
                    setInterestSegmentMap(prevMap => (
                        {
                            ...prevMap,
                            [interestSegmentId]: savedInterestSegment
                        }
                    ));
                });
        } else {
            return Promise.reject();
        }
    }

    function handleDeleteInterestSegment(interestSegmentId: string) {
        if (authenticatedUserContext.authenticatedUser) {
            return interestSegmentsClient.deleteInterestSegment(authenticatedUserContext.authenticatedUser.user.id, interestSegmentId)
                .then(() => {
                    setInterestSegmentIds(prevIds => prevIds === undefined ? undefined : prevIds.filter(id => id !== interestSegmentId));
                    setInterestSegmentMap(prevMap => {
                        if (prevMap === undefined) {
                            return undefined;
                        }
                        const {[interestSegmentId]: deletedInterestSegment, ...rest} = prevMap;
                        return rest;
                    });
                });
        } else {
            return Promise.reject();
        }
    }

    function handleAddInterestSegment(description: string): Promise<void> {
        if (authenticatedUserContext.authenticatedUser) {
            return interestSegmentsClient.addInterestSegment(authenticatedUserContext.authenticatedUser.user.id, description)
                .then(savedInterestSegment => {
                    setInterestSegmentIds(prevIds => prevIds === undefined ? [savedInterestSegment.id] : [...prevIds, savedInterestSegment.id]);
                    setInterestSegmentMap(prevMap => (
                        {
                            ...prevMap,
                            [savedInterestSegment.id]: savedInterestSegment
                        }
                    ));
                });
        } else {
            return Promise.reject();
        }
    }

    function setInterestSegments(interestSegments: InterestSegment[]) {
        setInterestSegmentIds(interestSegments.map(interestSegment => interestSegment.id));
        setInterestSegmentMap(interestSegments.reduce((prev, next) => ({...prev, [next.id] : next}), {}));
    }

    const syncInterestSegments = useCallback(() => {
        if (authenticatedUserContext.authenticatedUser) {
            return interestSegmentsClient.getInterestSegments(authenticatedUserContext.authenticatedUser.user.id)
                .then(interestSegments => {
                    setInterestSegments(interestSegments);
                }).catch(error => {
                    console.error('Failed to re-sync interest segments', error);
                });
        } else {
            return Promise.reject();
        }
    }, [authenticatedUserContext.authenticatedUser, interestSegmentsClient]);

    useEffect(() => {
        syncInterestSegments();
        if (isNewUser) {
            onOpenOnboarding();
        }
    }, [isNewUser, onOpenOnboarding, syncInterestSegments]);

    return (  
        <div className="Home sm:bg-slate-50 space-y-2">
            {justUnsubscribed ? (
                <div className="bg-secondary text-white w-screen flex items-center justify-center">
                <p className="text-sm tracking-widest text-center p-3">
                    You've been unsubscribed. Dive back in!
                </p>
              </div>
            ) : authenticatedUserContext.authenticatedUser?.user.subscriptionTier === SubscriptionTier.Inactive && !authenticatedUserContext.authenticatedUser?.user.trialed ? (
                <div className="bg-secondary text-white w-screen flex items-center justify-center">
                <p className="text-sm tracking-widest text-center p-3">
                    View the set up guide to start getting newsletters!
                </p>
                <div className="p-2">
                    <Button className="uppercase" color="primary" onPress={onOpenOnboarding}>
                        Get Started
                    </Button>
                </div>
              </div>
            ) : justSubscribed && (
                <div className="bg-secondary text-white w-screen flex items-center justify-center">
                <p className="text-sm tracking-widest text-center p-3">
                    You're in! Going forward, look for your Daily Dive from team@edgedive.com.
                </p>
                </div>
            )}

            <Card className="p-2" shadow="none">
                <CardBody>
                <div className="flex flex-row items-center space-x-1 mb-2">
                <p className="block font-bold text-start mr-1">Tracked Topics</p>
                    
                    <Popover placement="bottom" className="flex-1">
                        <PopoverTrigger>
                            <Button className="border rounded-full" isIconOnly color="primary" variant="light"><FaCircleInfo /></Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <div className="py-2">
                            <div className="text-small">Clear, well-defined topics tend to work better for our AI. If you find a really lengthy topic isn't quite giving you what you want, try breaking it down into two or more.</div>
                            </div>
                        </PopoverContent>
                    </Popover>
                    <Button className="uppercase border rounded-full" color="primary" variant="light" isIconOnly onPress={(e) => topicSetupDisclosure.onOpen()}>
                        <FaWandMagicSparkles size={13} />
                    </Button>
                    
                </div>
                <p className="text-small">Add and edit your tracked topics. Review the related articles to ensure your dives will return relevant info. </p>
                <InterestsManager
                    interestSegmentIds={interestSegmentIds === undefined ? [] : interestSegmentIds}
                    interestSegmentMap={interestSegmentMap === undefined ? {} : interestSegmentMap}
                    onAddInterestSegment={handleAddInterestSegment}
                    onUpdateInterestSegment={handleUpdateInterestSegment}
                    onDeleteInterestSegment={handleDeleteInterestSegment}
                />
                <Spacer y={4}/>
                </CardBody>
            </Card>



            <Card className="p-2" shadow="none">
                <CardBody>
                <p className="block font-bold text-start">Request New Sources</p>
                <p className="text-small">Request new tracked sources to improve coverage of niche topics or specific websites</p>
                <Button className="my-2 uppercase sm:w-1/4" color="primary" onPress={sourceRequestDisclosure.onOpen}>
                    Request Sources
                </Button>
                </CardBody>
            </ Card>

            <Card className="p-2" shadow="none">
                <CardBody>
                <p className="block font-bold text-start">Feedback</p>
                <p className="text-small">Thoughts? Suggestions? Complaints? Get in touch! </p>
                <Button className="my-2 uppercase sm:w-1/4" color="primary" onPress={feedbackDisclosure.onOpen}>
                    Submit Feedback
                </Button>
                </CardBody>
            </ Card>

            {authenticatedUserContext.authenticatedUser?.user.subscriptionTier === SubscriptionTier.Employee && 
                <Card className="p-2" shadow="none">
                    <CardBody>
                    <p className="block font-bold text-start">Dive Testing</p>
                    <Button className="my-2 uppercase sm:w-1/4" color="warning" onPress={sendManagerDisclosure.onOpen}>
                        Open Dive Tester
                    </Button>
                    </CardBody>
                </ Card>
            }

            <Modal isOpen={sourceRequestDisclosure.isOpen} onOpenChange={sourceRequestDisclosure.onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                    <>
                        <ModalHeader>Request new sources by pasting URLs here. </ModalHeader>
                        <ModalBody>
                        <p className="text-small">RSS feeds will be added most quickly.</p>
                        <SourceRequestManager />
                        <Spacer y={2}/>
                        </ModalBody>
                    </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={feedbackDisclosure.isOpen} onOpenChange={feedbackDisclosure.onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                    <>
                        <ModalHeader>Thoughts? Suggestions? Complaints?</ModalHeader>
                        <ModalBody>
                        <FeedbackManager />
                        <Spacer y={2}/>
                        </ModalBody>
                    </>
                    )}
                </ModalContent>
            </Modal>

            {interestSegmentIds !== undefined && interestSegmentMap !== undefined &&
                <>
                    <Modal size="xl" isOpen={isOnboardingOpen} onClose={onCloseOnboarding}>
                        <OnboardingManager
                            isNewUser={isNewUser}
                            topicSetUpNeeded={interestSegmentIds.length === 0}
                            onClose={onCloseOnboarding}
                            setInterestSegments={setInterestSegments}
                            interestSegments={interestSegmentIds.map(id => interestSegmentMap[id])}
                        />
                    </Modal>
                    <Modal size="xl" isOpen={topicSetupDisclosure.isOpen} onOpenChange={topicSetupDisclosure.onOpenChange}>
                        <TopicSetupModalContent topicSetUpNeeded={interestSegmentIds?.length === 0} onCompleteSetup={topicSetupDisclosure.onClose} setInterestSegments={setInterestSegments} />
                    </Modal>
                </>
            }

            {authenticatedUserContext.authenticatedUser?.user.subscriptionTier === SubscriptionTier.Employee && 
                <Modal isOpen={sendManagerDisclosure.isOpen} onOpenChange={sendManagerDisclosure.onOpenChange}>
                    <ModalContent>
                        {(onClose) => (
                        <>
                            <ModalHeader>Send one-off test dives.</ModalHeader>
                            <ModalBody>
                            <NewsletterSendManager />
                            <Spacer y={2}/>
                            </ModalBody>
                        </>
                        )}
                    </ModalContent>
                </Modal>
            }
        </div>
    );
}

export default Home;