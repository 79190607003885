import { PressEvent } from "@react-types/shared";
import { SubscriptionTier } from "../storyteller-api";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Button, Card } from '@nextui-org/react';
import axiosPrivate from "../utils/axios-private";
import { useState } from "react";
import { UsersClient } from "../utils/users-client";
import { useNavigate } from "react-router-dom";

function SubscriptionsManager() {
    const navigate = useNavigate();
    const authenticatedUserContext = useAuthenticatedUser();
    const usersClient = new UsersClient(authenticatedUserContext);
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    
    function handleCustomerPortalRedirect(e: PressEvent) {
        setIsLoadingLink(true);
        usersClient.getManageSubscriptionLink(authenticatedUserContext)
            .then(response => {
                window.open(decodeURIComponent(response), '_self');
            })
            .catch(error => {
                console.error("Failed to get customer portal redirect!", error);
            })
            .finally(() => setIsLoadingLink(false));
    }

    function handleCheckoutRedirect(e: PressEvent) {
        setIsLoadingLink(true);
        axiosPrivate(authenticatedUserContext).get(process.env.REACT_APP_STORYLINES_BE_BASE_URL + "/users/" + authenticatedUserContext.authenticatedUser?.user.id + "/subscriptions/checkout")
            .then(response => {
                if (response.status === 200) {
                    window.open(decodeURIComponent(response.data), '_self');
                }
            })
            .catch(error => {
                console.error("Failed to get free trial checkout redirect!", error);
            })
            .finally(() => setIsLoadingLink(false));
    }

    function handleStartDives(e: PressEvent) {
        if (authenticatedUserContext.authenticatedUser) {
            setIsLoadingLink(true);
            usersClient.startFreeSubscription(authenticatedUserContext.authenticatedUser?.user.id)
                .then(user => {
                    authenticatedUserContext.setAuthenticatedUser(prev => prev !== null ? { ...prev, user: user } : null)
                    navigate('?subscribed=true')
                })
                .catch(error => {
                    console.error("Failed to start the free trial!", error);
                })
                .finally(() => setIsLoadingLink(false));
        }
    }

    function handleCancelDives(e: PressEvent) {
        if (authenticatedUserContext.authenticatedUser) {
            setIsLoadingLink(true);
            usersClient.cancelFreeSubscription(authenticatedUserContext.authenticatedUser?.user.id)
                .then(user => {
                    authenticatedUserContext.setAuthenticatedUser(prev => prev !== null ? { ...prev, user: user } : null)
                    navigate('?unsubscribe=true')
                })
                .catch(error => {
                    console.error("Failed to cancel the subscription!", error);
                })
                .finally(() => setIsLoadingLink(false));
        }
    }

    return (
        <div className="SubscriptionsManager">
            {authenticatedUserContext.authenticatedUser?.user.subscriptionTier === SubscriptionTier.Inactive ? (
                <Button className="uppercase font-sans" color="success" type="submit" isLoading={isLoadingLink} onPress={handleStartDives}>Start my daily dives</Button>
            ) : (
                <Button className="uppercase font-sans" color="primary" type="submit" isLoading={isLoadingLink} onPress={handleCancelDives}>Pause my dives</Button>
            )}
        </div>
    );
}

export default SubscriptionsManager;