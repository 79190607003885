import { ChangeEventHandler, FormEvent, FormEventHandler, useEffect, useRef, useState } from 'react';
import { authenticateWithGoogle, requestSignUpLink, requestSignUpOrLoginLink } from '../../utils/auth-client';
import { Button, Card, Input, Spacer} from '@nextui-org/react';
import { GoogleLogin } from '@react-oauth/google';
import { useAuthenticatedUser } from '../../utils/auth-context';
import { useNavigate } from 'react-router-dom';

const GoogleSignInButton = () => {
    const authenticatedUserContext = useAuthenticatedUser();
    const navigate = useNavigate();

    const ref = useRef<HTMLHeadingElement>(null); // Step 1: useRef to reference the outer div
    const [width, setWidth] = useState(0); // Step 2: useState to track the div's width
  
    // Step 3: useEffect to update the width on mount and window resize
    useEffect(() => {
      const updateWidth = () => {
        if (ref.current) {
          setWidth(ref.current.offsetWidth); // Update the width state based on the div's current width
        }
      };
  
      updateWidth(); // Set initial width
  
      window.addEventListener('resize', updateWidth); // Update width on window resize
  
      // Cleanup the event listener
      return () => window.removeEventListener('resize', updateWidth);
    }, []);
  
    return (
      <div ref={ref} className="w-full h-auto flex justify-center items-center">
        <GoogleLogin
            onSuccess={credentialResponse => {
                console.log(credentialResponse);
                if (credentialResponse.credential) {
                    authenticateWithGoogle(credentialResponse.credential)
                        .then(authenticationData => {
                            authenticatedUserContext.setAuthenticatedUser(authenticationData);
                            navigate(authenticationData.isNewUser ? '/?newUser=true' : '/', { replace: true });
                        });
                } else {
                    console.error("Google authentication failed: no credentials returned.");
                }
            }}
            onError={() => {
                console.log('Login Failed');
            }}
            useOneTap
            theme='filled_black'
            size='large'
            shape='pill'
            width={width}
            text='continue_with'
        />
      </div>
    );
  };

interface AuthenticationLinkFormParams {
    formHeadingText: string;
    submitButtonText: string;
    email: string;
    onEmailChange: ChangeEventHandler<HTMLInputElement>
}

export function AuthenticationLinkForm({ formHeadingText, submitButtonText,email, onEmailChange }: AuthenticationLinkFormParams) {
    const [linkRequestInProgress, setLinkRequestInProgress] = useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();

    const onLinkRequest: FormEventHandler<HTMLFormElement> = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLinkRequestInProgress(true);
        setFailureMessage(undefined);
        try {
            await requestSignUpOrLoginLink(email);
            setLinkSent(true);
        } catch (error) {
            console.error("Failed to request authentication link!", error);
            setFailureMessage("Something went wrong. Please try again.");
        }
        setLinkRequestInProgress(false);
    };

    return (
    <Card className="" shadow="none">
       <form className='get-new-auth-link-form' onSubmit={onLinkRequest}>
            <p className='text-lg font-bold text-center py-5'>{formHeadingText}</p>
            <Input className="input-group"
                isRequired
                label="email"
                type="email"
                placeholder="readmynews@for.me"
                value={email}
                disabled={linkSent}
                onChange={onEmailChange}
            >
            </Input>
            <Spacer y={4} />
            <Button className="uppercase font-sans rounded-full" color="primary" type="submit" disabled={linkSent} isLoading={linkRequestInProgress} fullWidth>
                {!linkSent ? submitButtonText : "Email sent!"}
            </Button>
            <Button className='uppercase w-full my-2' color='primary' variant='light' isDisabled>or</Button>
            <GoogleSignInButton />
            <Spacer y={4} />
            
            {linkSent &&
            
                <>
                    <p className="text-md text-center">Check your inbox for a login link to Edgedive!</p>
                    <Spacer y={4} />
                </>
                }

            {failureMessage &&
                <>
                    <p className="text-md text-center">{failureMessage}</p>
                    <Spacer y={4} />
                </>}
        </form>
        </Card>
    );
}
