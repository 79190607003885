import { Link } from "@nextui-org/react";

function AppFooter() {
    return (
      <footer className="flex flex-wrap p-4 text-sm text-gray-500">
        <div className="flex flex-col sm:w-1/3 w-full p-1">
          <p className="sm:text-left text-center">© 2023 VillageAI Inc. All rights reserved.</p>
        </div>
        <div className="flex flex-col sm:w-1/3 w-full p-1">
          <p className="text-center">Contact us at: <Link className="text-sm" href="mailto:support@edgedive.com" color="secondary">support@edgedive.com</Link></p>
        </div>
        <div className="flex flex-col sm:w-1/3 w-full p-1">
          <p className="sm:text-right text-center">View our <Link className="text-sm" href="privacy-policy.html" color="secondary">privacy policy.</Link></p>
        </div>
      </footer>
    );
}

export default AppFooter;