import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuthenticatedUser } from '../utils/auth-context';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, useDisclosure } from '@nextui-org/react';
import { ArticlePreview, InterestSegment } from '../storyteller-api';
import { FaCheck, FaTrashCan } from "react-icons/fa6";
import { ArticlePreviewsClient } from '../utils/article-previews-client';
import InterestSegmentPreview from './InterestSegmentPreview';

interface InterestSegmentManagerProps {
    interestNumber: number
    initialInterestSegment: InterestSegment
    onUpdate: (interestSegment: InterestSegment) => Promise<void>
    onDelete: () => Promise<void>
}

function InterestSegmentManager(props: InterestSegmentManagerProps) {
    const authenticatedUserContext = useAuthenticatedUser();
    const articlePreviewsClient = useMemo(() => new ArticlePreviewsClient(authenticatedUserContext), [authenticatedUserContext]);

    const [interestSegment, setInterestSegment] = useState<InterestSegment>(props.initialInterestSegment);
    const [articlePreviews, setArticlePreviews] = useState<Array<ArticlePreview>>([]);
    const [updating, setUpdating] = useState<boolean>(false);
    const [isLimitExceeded, setIsLimitExceeded] = useState(false);

    const deleteDisclosure = useDisclosure();

    function onDescriptionTextAreaChange(newDescription: string): void {
        if (newDescription.length <= 500) {
            setIsLimitExceeded(false);
            setInterestSegment({
                ...interestSegment,
                description: newDescription
            });
        } else {
            setIsLimitExceeded(true);
        }
    }

    function onUpdateButtonClick(): Promise<void> {
        setUpdating(true);
        return props.onUpdate(interestSegment)
            .catch(error => console.error('Failed to update interest segment', error))
            .finally(() => setUpdating(false));
    }

    function onDeleteButtonClick(): Promise<void> {
        setUpdating(true);
        return props.onDelete()
            .catch(error => console.error('Failed to delete interest segment', error))
            .finally(() => setUpdating(false));
    }

    const loadArticlePreviews = useCallback(() => {
        if (authenticatedUserContext.authenticatedUser) {
            return articlePreviewsClient.findByInterestSegment(authenticatedUserContext.authenticatedUser.user.id, props.initialInterestSegment.id)
                .then(articlePreviews => {
                    setArticlePreviews(articlePreviews);
                }).catch(error => {
                    console.error('Failed to load article previews', error);
                });
        } else {
            return Promise.reject();
        }
    }, [authenticatedUserContext.authenticatedUser, articlePreviewsClient, props.initialInterestSegment.id]);

    useEffect(() => {
        loadArticlePreviews();
    }, [loadArticlePreviews, props.initialInterestSegment]);

    return (
        <div className="flex flex-wrap">
            <Textarea className="flex flex-col w-full sm:w-1/2 py-2 px-2"
                value={interestSegment.description}
                variant="bordered"
                isDisabled={updating}
                onChange={(e) => onDescriptionTextAreaChange(e.target.value)}
                minRows={2}
                isInvalid={isLimitExceeded}
                errorMessage={isLimitExceeded ? "Please limit your topic to 500 characters or less" : undefined}
                startContent={
                    <Button className='mr-2' isDisabled isIconOnly color="secondary">{props.interestNumber}</Button>
                }
                endContent={
                    updating ? (
                        <Button
                            variant="light"
                            color='primary'
                            isLoading
                            isDisabled
                            isIconOnly
                        >
                        </Button>
                    ) : interestSegment.description === props.initialInterestSegment.description ? (
                        <Button
                            variant="light"
                            color='danger'
                            isLoading={updating}
                            isDisabled={updating}
                            isIconOnly
                            onClick={(e) => deleteDisclosure.onOpen()}
                        >
                            <FaTrashCan size={15} />
                        </Button>
                    ) : (
                        <Button
                            variant="light"
                            color='success'
                            isLoading={updating}
                            isDisabled={updating || !interestSegment.description}
                            isIconOnly
                            onClick={(e) => onUpdateButtonClick()}
                        >
                            <FaCheck size={15} />
                        </Button>
                    )
                }
            />
            <InterestSegmentPreview articlePreviews={articlePreviews}/>
            <Modal isOpen={deleteDisclosure.isOpen} onOpenChange={deleteDisclosure.onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                    <>
                        <ModalHeader>Delete this tracked topic?</ModalHeader>
                        <ModalBody>{interestSegment.description}</ModalBody>
                        <ModalFooter>
                            <Button className='w-full' color="primary" type="submit" onPress={onClose}>Cancel</Button>
                            <Button className='w-full' color="danger" onPress={onDeleteButtonClick}>Delete</Button>
                        </ModalFooter>
                    </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default InterestSegmentManager;