import { Accordion, AccordionItem, Card, Link, Listbox, ListboxItem } from "@nextui-org/react";
import { ArticlePreview } from "../storyteller-api";
import { useState } from "react";
import { FaLink } from "react-icons/fa6";

interface InterestSegmentPreviewProps {
    articlePreviews: Array<ArticlePreview>
}

function InterestSegmentPreview(props: InterestSegmentPreviewProps) {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Card className="flex flex-col w-full sm:w-1/2 py-2 px-2 bg-gray-50 my-2 shadow-none">
            <Accordion isCompact onSelectionChange={(_) => setOpen(prev => !prev)}>
        {props.articlePreviews.length > 0 ? (
              <AccordionItem
                title={open ? (<p><b>Related Articles:</b></p>) : (<p><b>Related Article:</b> {props.articlePreviews[0].title}</p>)}
                textValue={open ? "Related Articles:" : `Related Article: ${props.articlePreviews[0].title}`}
                subtitle={open ? "" : "Expand for more related content"}
              >
                <Listbox color="secondary" variant="flat">
                {props.articlePreviews.map(articlePreview => (
                    <ListboxItem key={articlePreview.id} className="w-fit text-secondary rounded-2xl" startContent={<FaLink className="mr-1" size={15}/>} textValue={articlePreview.title}>
                        <Link key={articlePreview.id} className="text-secondary whitespace-normal" isExternal href={articlePreview.link}>{articlePreview.title}</Link>
                    </ListboxItem>
                ))}
                </Listbox>
              </AccordionItem>
        ) : (
              <AccordionItem subtitle="No content available yet" textValue="No content available yet" />
        )}
            </Accordion>
        </Card>
    );
}

export default InterestSegmentPreview;