import { FormEventHandler, useState } from "react";
import { useAuthenticatedUser } from "../utils/auth-context";
import { Button, Card, Spacer, Textarea} from '@nextui-org/react';
import { Navigate } from "react-router-dom";

function FeedbackManager() {
    const authenticatedUserContext = useAuthenticatedUser();
    const [isFeedbackInProgress, setIsFeedbackInProgress] = useState<boolean>(false);
    const [isJustUpdated, setIsJustUpdated] = useState<boolean>(false);
    const [feedbackValue, setFeedbackValue] = useState(''); // State to manage feedback value

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault(); // Prevents default form submission (and redirection)
        const form = e.currentTarget; // Use currentTarget instead of target
        const formData = new FormData(form);
        setIsFeedbackInProgress(true);
        await fetch('https://script.google.com/macros/s/AKfycbxWhUdrmyl42tg9lak3HOz9lME-6RXdHzz_v23KtyNkxhsncms7VLYFJ7NfuocaIZyp/exec', {
            method: 'POST',
            body: formData,
        })
        setIsFeedbackInProgress(false);
        setIsJustUpdated(true);
        setFeedbackValue('');
    };

    return (
        <div className="FeedbackManager">
            <Card shadow="none">
                { authenticatedUserContext.authenticatedUser ? (
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                    }}>
                        <input 
                            type="hidden" 
                            name="date" 
                            value={new Date(Date.now()).toLocaleString()}>
                        </input>
                        <input 
                            type="hidden" 
                            name="email" 
                            value={authenticatedUserContext.authenticatedUser.user.email}>
                        </input>
                        <Textarea
                            name="feedback"
                            value={feedbackValue}
                            onChange={(e) => setFeedbackValue(e.target.value)}
                            minRows={2}
                            label="Type here..."
                            fullWidth
                        >
                        </Textarea>
                        <Spacer y={4} /> 
                        <Button className="uppercase font-sans" color="primary" type="submit" fullWidth
                            isLoading={isFeedbackInProgress}>
                            Submit Feedback
                        </Button>
                    </form>
                ) : (
                    <Navigate to='/login' />
                )}
                {isJustUpdated &&
                    <>
                        <Spacer y={4} />
                        <p>Thanks for the feedback! We'll get back to you soon.</p>
                    </>
                }
            </Card>
        </div>

    );
}

export default FeedbackManager;