import axios, { AxiosInstance } from 'axios';
import { AuthenticatedUserContext } from './auth-context';
import { refreshAccessToken } from './auth-client';

function axiosPrivate(authenticatedUserContext: AuthenticatedUserContext): AxiosInstance {
    
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use((config) => {
        console.debug("About to send request: " + JSON.stringify(config));
        if (authenticatedUserContext.authenticatedUser && !config.headers.Authorization) {
            console.debug("Adding auth header to request: " + JSON.stringify(config));
            config.headers.Authorization = `Bearer ${authenticatedUserContext.authenticatedUser.authenticationToken}`;
        }
        console.debug("Sending request: " + JSON.stringify(config));
        return config;
      });

    axiosInstance.interceptors.response.use(
        response => {
            console.debug("Received response: " + JSON.stringify(response));
            return response;
        },
        async (error) => {
            console.debug("Received error response: " + error);
            const originalRequest = error.config;
            console.debug("Original request: " + originalRequest);
            if (error.response.status === 403 && !originalRequest._retry) {
                console.debug("Found 403 and original request is not a retry.")
                originalRequest._retry = true;
                try {
                    console.debug("Refreshing access token.");
                    const data = await refreshAccessToken(); // Refresh the access token
                    console.debug("Successfully refreshed access token: " + JSON.stringify(data));
                    authenticatedUserContext.setAuthenticatedUser(data); // Update the authenticated user
                    console.debug("Triggered update to authenticatedUserContext.");
                    error.config.headers.Authorization = 'Bearer ' + data.authenticationToken;
                    console.debug("Updated original request headers to use new auth token. Kicking off retry: " + JSON.stringify(originalRequest));
                    return await axiosInstance(originalRequest); // Retry the original request
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                        console.debug("Received 401 from server. Clearing authenticated user and redirecting to login.");
                        authenticatedUserContext.setAuthenticatedUser(null); // Clear invalid access token
                        window.location.href = '/login'; // Redirect to login page
                        return Promise.reject(error);
                    }
                    Promise.reject(error);
                }
            }
            return Promise.reject(error);
        }
    );
    
    return axiosInstance;
}

export default axiosPrivate;