import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Spacer, Textarea, useDisclosure } from '@nextui-org/react';
import { InterestSegment } from '../storyteller-api';
import { FaPlus, FaWandMagicSparkles } from "react-icons/fa6";
import InterestSegmentManager from './InterestSegmentManager';
import { TopicSetupModalContent } from './OnboardingManager';

export interface InterestSegmentMap {
    [id: string]: InterestSegment
}

interface InterestsManagerProps {
    interestSegmentIds: Array<string>,
    interestSegmentMap: InterestSegmentMap,
    onAddInterestSegment: (description: string) => Promise<void>,
    onUpdateInterestSegment: (interestSegmentId: string, interestSegment: InterestSegment) => Promise<void>,
    onDeleteInterestSegment: (interestSegmentId: string) => Promise<void>
}

function InterestsManager(props: InterestsManagerProps) {
    const [newInterestSegmentText, setNewInterestSegmentText] = useState<string>("");
    const [addingNewSegment, setAddingNewSegment] = useState<boolean>(false);
    const [isLimitExceeded, setIsLimitExceeded] = useState(false);

    function onAddInterestTextAreaChange(updatedText: string): void {
        if (updatedText.length <= 500) {
            setIsLimitExceeded(false);
            setNewInterestSegmentText(updatedText);;
        } else {
            setIsLimitExceeded(true);
        }
    }

    function onAddInterestSegmentButtonClick(): Promise<void> {
        setAddingNewSegment(true);
        return props.onAddInterestSegment(newInterestSegmentText)
            .then(() => setNewInterestSegmentText(""))
            .catch(error => console.error("Failed to add interest segment.", error))
            .finally(() => setAddingNewSegment(false));
    }

    return (
        <div className='InterestsManager m-1 -mx-2'>
            <Spacer y={2} />
            { props.interestSegmentIds.map((interestSegmentId, index) => (
                <InterestSegmentManager
                    key={interestSegmentId}
                    interestNumber={index + 1}
                    initialInterestSegment={props.interestSegmentMap[interestSegmentId]}
                    onUpdate={(interestSegment: InterestSegment) => props.onUpdateInterestSegment(interestSegmentId, interestSegment)}
                    onDelete={() => props.onDeleteInterestSegment(interestSegmentId)}
                />
            ))}
            <Spacer y={2} />
            { props.interestSegmentIds.length < 10 &&
                <div className="flex flex-wrap">
                    <Textarea className="flex flex-col w-full sm:w-1/2 py-2 px-2"
                        placeholder="Add up to 10 topics..."
                        variant="bordered"
                        minRows={2}
                        onChange={(e) => onAddInterestTextAreaChange(e.target.value)}
                        value={newInterestSegmentText}
                        isDisabled={addingNewSegment}
                        isInvalid={isLimitExceeded}
                        errorMessage={isLimitExceeded ? "Please limit your topic to 500 characters or less" : undefined}
                        startContent={
                            <Button className='mr-2' isDisabled isIconOnly color="secondary">{props.interestSegmentIds.length + 1}</Button> 
                        }
                        endContent={
                            <Button
                                color="success"
                                variant="flat"
                                onClick={(e) => onAddInterestSegmentButtonClick()}
                                isLoading={addingNewSegment}
                                isDisabled={addingNewSegment || !newInterestSegmentText}
                                isIconOnly
                            >
                                { !addingNewSegment &&
                                    <FaPlus size={15} />
                                }
                            </Button>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default InterestsManager;


