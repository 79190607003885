import { AxiosRequestConfig } from "axios";
import { Interests, UpdateInterestsRequest, UsersApi, UpdateInterestsResponse, User } from "../storyteller-api/api"
import { AuthenticatedUserContext } from "./auth-context";
import axiosPrivate from "./axios-private";

export class UsersClient {
    protected api: UsersApi;

    constructor(authenticatedUserContext: AuthenticatedUserContext) {
        this.api = new UsersApi(undefined, process.env.REACT_APP_STORYLINES_BE_BASE_URL, axiosPrivate(authenticatedUserContext));
    }

    public async updateInterests(userId: string, interestsText: string): Promise<UpdateInterestsResponse> {
        let updateInterestsRequest: UpdateInterestsRequest = {
            interestsText: interestsText
        };

        try {
            return (await this.api.updateInterests(userId, updateInterestsRequest, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to update user interests", error);
            throw error;
        }
    }

    public async updateInferredInterest(userId: string, interestsText: string, inferredInterestIndex: number): Promise<UpdateInterestsResponse> {
        let updateInterestsRequest: UpdateInterestsRequest = {
            interestsText: interestsText
        };

        try {
            return (await this.api.updateInferredInterest(userId, inferredInterestIndex, updateInterestsRequest, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to update user inferred interests", error);
            throw error;
        }
    }

    public async deleteInferredInterest(userId: string, inferredInterestIndex: number): Promise<UpdateInterestsResponse> {
        try {
            return (await this.api.deleteInferredInterest(userId, inferredInterestIndex, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to delete user inferred interests", error);
            throw error;
        }
    }

    public async addInferredInterest(userId: string, interestsText: string): Promise<UpdateInterestsResponse> {
        let updateInterestsRequest: UpdateInterestsRequest = {
            interestsText: interestsText
        };

        try {
            return (await this.api.addInferredInterest(userId, updateInterestsRequest, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error("Failed to add user inferred interests", error);
            throw error;
        }
    }

    public async getInterests(userId: string): Promise<Interests> {
        try {
            let response = await this.api.getLatestInterests(userId, this.getBasicRequestConfig());
            return response.data;
        } catch (error) {
            console.error("Failed to get latest user interests", error);
            throw error;
        }
    }

    public getManageSubscriptionLink(authenticatedUserContext: AuthenticatedUserContext): Promise<string> {
        return axiosPrivate(authenticatedUserContext).get(process.env.REACT_APP_STORYLINES_BE_BASE_URL + "/users/" + authenticatedUserContext.authenticatedUser?.user.id + "/subscriptions/manage")
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error("Failed to get customer portal redirect!", error);
            });
    }

    public async startFreeTrial(userId: string): Promise<User> {
        try {
            let response = await this.api.startFreeTrial(userId, this.getBasicRequestConfig());
            return response.data;
        } catch (error) {
            console.error("Failed to start the free trial for the user", error);
            throw error;
        }
    }

    public async startFreeSubscription(userId: string): Promise<User> {
        try {
            let response = await this.api.startFreeSubscription(userId, this.getBasicRequestConfig());
            return response.data;
        } catch (error) {
            console.error("Failed to start the free subscription for the user", error);
            throw error;
        }
    }

    public async cancelFreeSubscription(userId: string): Promise<User> {
        try {
            let response = await this.api.cancelFreeSubscription(userId, this.getBasicRequestConfig());
            return response.data;
        } catch (error) {
            console.error("Failed to cancel the free subscription for the user", error);
            throw error;
        }
    }

    private getBasicRequestConfig(): AxiosRequestConfig {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}