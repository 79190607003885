import { AxiosRequestConfig } from "axios";
import { AuthenticatedUserResponse, AuthenticationMethod, AuthenticationMethodTypeEnum, Configuration, GoogleAuthentication, LinkAuthentication, LinkAuthenticationRequest, LoginUserRequest, UsersApi } from "../storyteller-api";

export async function requestSignUpOrLoginLink(email: string) {
    let linkAuthenticationRequest: LinkAuthenticationRequest = {
        email: email
    };
    await sendLinkAuthenticationRequest(linkAuthenticationRequest);
}

export async function requestSignUpLink(email: string) {
    let linkAuthenticationRequest: LinkAuthenticationRequest = {
        email: email,
        isNewUser: true
    };
    await sendLinkAuthenticationRequest(linkAuthenticationRequest);
}

export async function requestLoginLink(email: string) {
    let linkAuthenticationRequest: LinkAuthenticationRequest = {
        email: email,
        isNewUser: false
    };
    await sendLinkAuthenticationRequest(linkAuthenticationRequest);
}

async function sendLinkAuthenticationRequest(linkAuthenticationRequest: LinkAuthenticationRequest) {
    try {
        await api().requestLinkAuthentication(linkAuthenticationRequest, getRequestConfigWithCredentials());
    } catch (error) {
        console.error("Failed to send request for link authentication", error);
        throw error;
    }
}

export async function authenticateWithLink(email: string, token: string): Promise<AuthenticatedUserResponse> {
    const linkAuthentication: LinkAuthentication = {
        email: email,
        token: token
    }
    const authenticationMethod: AuthenticationMethod = {
        type: AuthenticationMethodTypeEnum.Link,
        authenticationData: linkAuthentication
    }
    const loginUserRequest: LoginUserRequest = {
        authenticationMethod: authenticationMethod
    };

    try {
        let response = await api().login(loginUserRequest, getRequestConfigWithCredentials());
        let responseData = response.data;
        if (responseData.authenticationToken && responseData.user) {
            return responseData;
        } else {
            throw new Error("Login request failed: authenticationToken or user not received");
        }
    } catch (error) {
        console.error('There was an error logging in the user: ', error);
        throw error;
    }
}

export async function authenticateWithGoogle(credential: string): Promise<AuthenticatedUserResponse> {
    const googleAuthentication: GoogleAuthentication = {
        credential: credential
    }
    const authenticationMethod: AuthenticationMethod = {
        type: AuthenticationMethodTypeEnum.Google,
        authenticationData: googleAuthentication
    }
    const loginUserRequest: LoginUserRequest = {
        authenticationMethod: authenticationMethod
    };

    try {
        let response = await api().login(loginUserRequest, getRequestConfigWithCredentials());
        let responseData = response.data;
        if (responseData.authenticationToken && responseData.user) {
            return responseData;
        } else {
            throw new Error("Login request failed: authenticationToken or user not received");
        }
    } catch (error) {
        console.error('There was an error logging in the user: ', error);
        throw error;
    }
}

export async function logout(email: string) {
    try {
        await api().logout(getRequestConfigWithCredentials());
    } catch (error) {
        console.error('There was an error logging out the user: ', error);
        throw error;
    }
}

export async function refreshAccessToken(): Promise<AuthenticatedUserResponse> {
    try {
        let response = await api().refreshAccessToken(getRequestConfigWithCredentials());
        let responseData = response.data;
        if (responseData.authenticationToken && responseData.user) {
            return responseData;
        } else {
            throw new Error("Refresh access token failed: authenticationToken or user not received");
        }
    } catch (error) {
        console.error('Failed to refresh access token: ', error);
        throw error;
    }
}

function api(authenticationToken?: string): UsersApi {
    let configuration = new Configuration({
        basePath: process.env.REACT_APP_STORYLINES_BE_BASE_URL,
        ...(authenticationToken && {accessToken: authenticationToken})
    });
    return new UsersApi(configuration);
}

function getRequestConfigWithCredentials(): AxiosRequestConfig {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        withCredentials: true
    };
}