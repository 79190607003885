import { useState } from 'react';
import { AuthenticationLinkForm } from './AuthenticationLinkForm';
import { useNavigate } from 'react-router';
import { useAuthenticatedUser } from '../../utils/auth-context';
import { Button, Card, Divider, Spacer } from '@nextui-org/react';

export function LoginForm() {
    const authenticatedUserContext = useAuthenticatedUser();
    const navigate = useNavigate();

    if (authenticatedUserContext.authenticatedUser) {
        navigate("/", { replace: true });
    }

    const [email, setEmail] = useState('');
    
    return (
        <div className='my-5'>
            <Card className="w-full md:w-1/2 max-w-md p-4 mx-auto" shadow="none">
                <AuthenticationLinkForm
                    formHeadingText="Let's get you authenticated."
                    submitButtonText="Continue with email"
                    email={email}
                    onEmailChange={(e) => setEmail(e.target.value)}
                />
            </Card>
        </div>
    );

}
