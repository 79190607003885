import { MouseEventHandler } from "react";
import { logout } from "../utils/auth-client";
import { useLocation, useNavigate } from "react-router";
import logotext from "./logotext.png";
import logo from "./logo.png";
import FeedbackManager from "./FeedbackManager";
import { useAuthenticatedUser } from "../utils/auth-context";
import {Spacer, Modal, ModalBody, ModalContent, ModalHeader, useDisclosure, Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, Image, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, ModalFooter} from "@nextui-org/react";
import { SubscriptionTier, User } from "../storyteller-api";
import SubscriptionsManager from "./SubscriptionsManager";
import { FaBars, FaBullhorn, FaDoorOpen, FaCode, FaLock } from "react-icons/fa6";
import SourceRequestManager from "./SourceRequestManager";
import { googleLogout } from "@react-oauth/google";
import { onButtonPressLink } from "../utils/event-utils";

function AppNav() {
    const feedbackDisclosure = useDisclosure();
    const sourceRequestDisclosure = useDisclosure();
    const privacyDisclosure = useDisclosure();

    const authenticatedUserContext = useAuthenticatedUser();
    const navigate = useNavigate();

    function loggedInDropDownMenu(user: User) {
      return (
        <DropdownMenu aria-label="Profile Actions" variant="flat" disabledKeys={["profile"]}>
          <DropdownItem key="profile" className="h-14 gap-2"
            description={`Subscription Tier: ${formatReadableSubscriptionTier(user.subscriptionTier)}`}>
            <p className="font-semibold">Signed in as</p>
            <p className="font-semibold">{user.email}</p>
          </DropdownItem>
          <DropdownItem key="Source Requests" onPress={sourceRequestDisclosure.onOpen} startContent={<FaCode />}>
            Request Sources
          </DropdownItem>
          <DropdownItem key="Feedback" onPress={feedbackDisclosure.onOpen} startContent={<FaBullhorn />}>
            Feedback
          </DropdownItem>
          <DropdownItem key="Privacy" onPress={privacyDisclosure.onOpen} startContent={<FaLock />}>
            Privacy
          </DropdownItem>
          <DropdownItem className="text-danger" color="danger" key="Log Out" onPress={onClickLogout} startContent={<FaDoorOpen />}>
              Log out
          </DropdownItem>
        </DropdownMenu>
      );
    }

    function loggedOutDropDownMenu() {
      return (
        <DropdownMenu aria-label="Profile Actions" variant="flat" disabledKeys={["profile"]}>
          <DropdownItem key="Privacy" onPress={privacyDisclosure.onOpen} startContent={<FaLock />}>
            Privacy
          </DropdownItem>
        </DropdownMenu>
      );
    }

    const onClickLogout = () => {
        if (authenticatedUserContext.authenticatedUser) {
        logout(authenticatedUserContext.authenticatedUser.user.email)
            .then(() => {
                authenticatedUserContext.setAuthenticatedUser(null);
                navigate('/');
            })
            .catch(error => console.error("Failed to log out!", error));
        }
        googleLogout();
    }

    return (
      <Navbar isBordered maxWidth="full">
        <NavbarContent as="div" justify="start">
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Button
              color="primary"
              variant="light"

              isIconOnly
            >
            <FaBars className="h-full" />
            </Button>
          </DropdownTrigger>
          { authenticatedUserContext.authenticatedUser ? (
            loggedInDropDownMenu(authenticatedUserContext.authenticatedUser.user)
          ) : (
            loggedOutDropDownMenu()
          )}
        </Dropdown>

        <Modal isOpen={sourceRequestDisclosure.isOpen} onOpenChange={sourceRequestDisclosure.onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader>Request new sources by pasting URLs here. </ModalHeader>
                <ModalBody>
                  <p className="text-small">RSS feeds will be added most quickly.</p>
                  <SourceRequestManager />
                  <Spacer y={2}/>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>

        <Modal isOpen={feedbackDisclosure.isOpen} onOpenChange={feedbackDisclosure.onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader>Thoughts? Suggestions? Complaints?</ModalHeader>
                <ModalBody>
                  <FeedbackManager />
                  <Spacer y={2}/>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>

        <Modal size="xl" isOpen={privacyDisclosure.isOpen} onClose={privacyDisclosure.onClose}>
          <ModalContent>
              {(onClose) => (
              <>
                  <ModalHeader>Your privacy is important to us.</ModalHeader>
                  <ModalBody>
                    <p>Important resources available to you:</p>
                    <Button
                      color="primary" variant="ghost"
                      onPress={(e) => onButtonPressLink("privacy-policy.html", true)}
                    >
                      View our privacy policy
                    </Button>
                    <Button
                      color="primary" variant="ghost"
                      onPress={(e) => onButtonPressLink("https://app.termly.io/notify/a6953d8e-816c-4bcd-8364-8c0c5af0737c", true)}
                    >
                      Data Subject Access Request
                    </Button>
                    <Spacer y={2}/>
                    <p>Contact us at <Link color="secondary" href="mailto:support@edgedive.com">support@edgedive.com.</Link></p>
                    <Spacer y={2}/>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="uppercase" color="primary" onPress={privacyDisclosure.onClose}>
                      Done
                    </Button>
                  </ModalFooter>
              </>
              )}
          </ModalContent>
      </Modal>

        </NavbarContent>

        <NavbarContent className="sm:hidden" justify="start">
          <NavbarBrand>
              <Link href="/">
              <Image 
              className="max-h-10"
                alt="edgedive logotext" 
                src={logo}
              />
              </Link>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <NavbarBrand>
            <Link href="/">
            <Image 
            className="max-h-10"
              alt="edgedive logotext" 
              src={logotext}
            />
            </Link>
          </NavbarBrand>
        </NavbarContent>
        
        <NavbarContent justify="end">
        {/* <NavbarItem>

        { authenticatedUserContext.authenticatedUser && 
          <>
          <Button className="hidden sm:flex uppercase font-sans" color="primary" onPress={onOpen}>Feedback</Button>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center">
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader>Thoughts? Suggestions? Complaints?</ModalHeader>
                    <ModalBody className="flex justify-center">
                      <FeedbackManager />
                      <Spacer y={2}/>
                    </ModalBody>
                  </>
                 )}
              </ModalContent>
            </Modal>
            </>
        }
          </NavbarItem>
          <NavbarItem>
            { authenticatedUserContext.authenticatedUser && 
              <Button className="hidden sm:flex uppercase font-sans"
                color="danger" 
                onClick={onClickLogout}>
                Log out
              </Button>
            }
          </NavbarItem> */}
          { authenticatedUserContext.authenticatedUser && 
            <>
          <NavbarItem className="p-2">
                <SubscriptionsManager />
          </NavbarItem>
         </>
          }
          </NavbarContent>

      </Navbar>
    );
}

function formatReadableSubscriptionTier(subscriptionTier: SubscriptionTier): string {
  switch (subscriptionTier) {
    case SubscriptionTier.Base:
      return "Basic";
    case SubscriptionTier.Beta:
      return "Beta";
    case SubscriptionTier.Free:
      return "Free";
    case SubscriptionTier.FreeTrial:
      return "Free Trial";
    case SubscriptionTier.Employee:
      return "Employee";
    case SubscriptionTier.Inactive:
      return "Unsubscribed";
    case SubscriptionTier.PaidTierPersonal:
      return "Pro";
    case SubscriptionTier.PaidTierProfessional:
      return "Enterprise";
    case SubscriptionTier.WaitList:
      return "Waitlist";
  }
}

export default AppNav;