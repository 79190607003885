import axios, { AxiosRequestConfig } from "axios";
import { NewsletterRateLimits, NewsletterSubscription, NewsletterType, NewsletterUpdate, NewslettersApi, RelevanceStrategy } from "../storyteller-api";
import { AuthenticatedUserContext } from "./auth-context";
import axiosPrivate from "./axios-private";

export class NewslettersClient {
    protected api: NewslettersApi;

    constructor(authenticatedUserContext?: AuthenticatedUserContext) {
        if (authenticatedUserContext) {
            this.api = new NewslettersApi(undefined, process.env.REACT_APP_STORYLINES_BE_BASE_URL, axiosPrivate(authenticatedUserContext));
        } else {
            this.api = new NewslettersApi(undefined, process.env.REACT_APP_STORYLINES_BE_BASE_URL);
        }
    }

    public async getInitialNewsletterUpdate(userId: string): Promise<NewsletterUpdate | null> {
        try {
            let response = await this.api.getNewsletterUpdate(userId, NewsletterType.Initial, undefined, this.getBasicRequestConfig());
            if (response.status === 200) {
                return response.data;
            } else if (response.status === 404) {
                return null;
            } else {
                throw new Error('Failed to retrieve initial newsletter update. Status: ' + response.status.toString());
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 404) {
                    return null;
                } else {
                    throw new Error('Failed to retrieve initial newsletter update: ' + error.response.status.toString());
                }
            }
            console.error('There was an error retrieving sent newsletter updates: ', error);
            throw error;
        }
    }
    

    public async unsubscribeWithLink(email: string, token: string): Promise<void> {
        try {
            await this.api.unsubscribeWithLink(email, token, this.getBasicRequestConfig());
        } catch (error) {
            console.error('There was an error unsubscribing: ', error);
            throw error;
        }
    }

    public async unsubscribe(newsletterId: string, userId: string) {
        try {
            await this.api.unsubscribe(newsletterId, userId, this.getBasicRequestConfig());
        } catch (error) {
            console.error('There was an error unsubscribing: ', error);
            throw error;
        }
    }

    public async subscribe(newsletterId: string, userId: string) {
        try {
            await this.api.subscribe(newsletterId, userId, this.getBasicRequestConfig());
        } catch (error) {
            console.error('There was an error subscribing: ', error);
            throw error;
        }
    }

    public async getSubscriptions(userId: string): Promise<NewsletterSubscription[]> {
        try {
            return (await this.api.getAll(userId, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error('There was an error retrieving subscriptions: ', error);
            throw error;
        }
    }

    public async sendInitialNewsletter(userId: string) {
        try {
            await this.api.sendInitial(userId, this.getBasicRequestConfig());
        } catch (error) {
            console.error('There was an error sending an initial newsletter: ', error);
            throw error;
        }
    }

    public async sendCustomNewsletter(userId: string) {
        try {
            await this.api.sendCustom(userId, RelevanceStrategy.InnerProduct, false, this.getBasicRequestConfig());
        } catch (error) {
            console.error('There was an error sending a custom newsletter: ', error);
            throw error;
        }
    }

    public async getRateLimits(userId: string): Promise<NewsletterRateLimits> {
        try {
            return (await this.api.getRateLimits(userId, this.getBasicRequestConfig())).data;
        } catch (error) {
            console.error('There was an error retrieving newsletter rate limits: ', error);
            throw error;
        }
    }

    private getBasicRequestConfig(): AxiosRequestConfig {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    };
}