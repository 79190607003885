import { AxiosRequestConfig } from "axios";
import { AuthenticatedUserContext } from "./auth-context";
import axiosPrivate from "./axios-private";
import { ArticlePreview, ArticlePreviewsApi, InterestSegment, InterestSegmentsApi, UpdateInterestsRequest } from "../storyteller-api";

export class ArticlePreviewsClient {
    protected api: ArticlePreviewsApi;

    constructor(authenticatedUserContext: AuthenticatedUserContext) {
        this.api = new ArticlePreviewsApi(undefined, process.env.REACT_APP_STORYLINES_BE_BASE_URL, axiosPrivate(authenticatedUserContext));
    }

    public async findByInterestSegment(userId: string, interestSegmentId: string): Promise<ArticlePreview[]> {
        try {
            let response = await this.api.findByInterestSegment(userId, interestSegmentId, this.getBasicRequestConfig());
            return response.data;
        } catch (error) {
            console.error("Failed to get article previews", error);
            throw error;
        }
    }

    private getBasicRequestConfig(): AxiosRequestConfig {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}